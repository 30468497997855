.project-reg {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    justify-content: center;
}

.project-reg .project-reg-main {
    display: flex;
    flex-direction: column;
    min-width: 860px;
    height: 100%;
    background-color: #FFF;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    box-sizing: border-box;
    padding: 10px 30px 100px 30px;
}

.project-reg .main-text {
    font-weight: 700;
    font-size: 18px;
    margin: 20px 0px;
}

.project-reg .project-reg-main .project-reg-info {
    height: 430px;
}

.project-reg .project-reg-main .project-reg-info .title {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    height: 60px;
    border: none;
    outline: none;
}

.project-reg .project-reg-main .project-reg-info .option {
    height: 40px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    margin: 20px 20px 20px 0;
    padding: 5px;
    box-sizing: border-box;
}

.project-reg .project-reg-main .project-reg-info .option-icon {
    color: #DDD;
    font-size: 12px;
    width: 15px;
    height: 35px;
    margin: 0 25px 0 0;
}

.project-reg .project-reg-main .project-reg-info .option-name {
    font-weight: 400;
    font-size: 14px;
    border: none;
    height: 35px;
    color: var(--default-text-color);
    display: flex;
    align-items: center;
}

.project-reg .project-reg-main .project-reg-info .option .option-name {
    /* width: 60px; */
    margin: 0 0 0 20px;
}

.project-reg .project-reg-main .project-reg-info .option-input {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 14px;
    border: none;
    outline: none;
    height: 35px;
    text-align: center;
}

.project-reg .project-reg-main .project-reg-info .input-group {
    margin: 0 0 0 20px;
}

.project-reg .project-reg-main .project-reg-info .option .option-input {
    margin: 0 0 0 20px;
}

.project-reg .project-reg-main .project-reg-info .option .option-input::placeholder {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
}

.project-reg .project-reg-main .project-reg-info .option-input.text {
    width: 50px;
}

.project-reg .project-reg-main .project-reg-info .option-input.date {
    width: 145px;
}

.project-reg .project-reg-main .project-reg-info .option-input.dropdown {
    width: 145px;
}

.project-reg .project-reg-main .project-reg-info .option-input-sub {
    font-family: 'Noto Sans KR';
    font-size: 14px;
    text-align: center;
    width: 100px;
    margin: 5px 0 5px 20px;
    height: 25px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    padding: 5px;
}

.project-reg .project-reg-main .project-reg-info .sub-text {
    font-weight: 700;
    font-size: 14px;
    width: 30px;
    color: var(--default-text-color-hint);
}

.project-reg .project-reg-main .project-reg-info .box {
    font-weight: 600;
    font-size: 14px;
    width: 64px;
    height: 22px;
    border-radius: 5px;
    margin: 5px 10px 5px 0;
}

.project-reg .project-reg-main .project-reg-info .box.recruit {
    color: #FFF;
    border: 1px solid var(--color-secondary-p1);
    background-color: var(--color-secondary-p1);
}

.project-reg .project-reg-main .project-reg-info .box.new {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.project-reg .project-reg-main .project-reg-info .reg-date {
    font-weight: 500;
    font-size: 12px;
}

.project-reg .project-reg-main .project-reg-info .ui.selection.dropdown.location {
    width: 140px;
}

.project-reg .project-reg-main .project-reg-info .ui.selection.dropdown .menu {
    width: 140px;
}

.project-reg .project-reg-main .project-reg-contents {
    height: 200px;
    border-top : 1px solid var(--default-text-color-hint);
}

.project-reg .project-reg-main .project-reg-contents .contents-input {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    height: 120px;
    border: none;
    outline: none;
    box-sizing: border-box;
    resize: none;
    overflow-y: auto;
}

.project-reg .project-reg-main .project-reg-add {
    height: 200px;
    border-top : 1px solid var(--default-text-color-hint);
}

.project-reg .project-reg-main .project-reg-add .file-list {
    width: 685px;
    height: 200px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--default-text-color-hint);
    outline: none;
    cursor:default;
    overflow: auto;
}

.project-reg .project-reg-main .project-reg-add .file-list-item {
    position: relative;
    width: 220px;
    height: 28px;
    margin: 10px;
    color: var(--color-gray-1);
    border: 1px solid var(--color-gray-1);
    border-radius: 10px;
}

.project-reg .project-reg-main .project-reg-add .file-list-item .part-divider {
    margin: 0 5px;
}

.project-reg .project-reg-main .project-reg-add .file-list-item .code.x.icon {
    color: var(--default-text-color-hint);
}

.project-reg .project-reg-main .project-reg-add .file-list-item .code.x.icon:hover {
    color: #FFF;
    background-color: #000;
}

.project-reg .project-reg-main .project-reg-add .button {
    font-weight: 700;
    font-size: 14px;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    margin: 0 5px 10px 20px;
    width: 90px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
}

.project-reg .project-reg-main .project-reg-add .button:hover {
    background-color: var(--color-primary);
    color: white;
}

.project-reg .project-reg-sub {
    height: 140px;
    background-color: #FFF;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px 3px #DADCE0;
    box-sizing: border-box;
    padding: 10px 20px;
}

.project-reg .project-reg-sub .button {
    font-weight: 700;
    font-size: 16px;
    width: 284px;
    height: 47px;
    border-radius: 5px;
    color: #3F6BCC;
    border: 1px solid #3F6BCC;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
}

.project-reg .project-reg-sub .button:hover {
    color: #FFF;
    background-color: var(--color-primary-p2);
}

.project-reg .project-reg-sub .button.reg {
    color: #FFF;
    background-color: var(--color-primary);
}

.project-reg .project-reg-sub .button.reg:hover {
    background-color: var(--color-primary-p2);
}

.project-reg .project-reg-sub .fa-circle-question {
    margin: 0 5px 0 0;
}

.project-reg .project-reg-sub .fa-floppy-disk {
    margin: 0 10px 0 0;
}

.project-reg .help-container {
    background-color: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.project-reg .help-container .close-button {
    font-size: 40px;
    font-weight: 700;
    color: #FFF;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    border-radius: 25px;
}

.project-reg .help-container .close-button:hover {
    background-color: #FFF;
    color: #000;
}