.Header-container {
    font-family: 'Noto Sans KR' !important;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--color-primary);
    z-index: 10;
}

.Header-container .logo-container {
    height: 100%;
}

.Header-container .logo {
    width: 120px;
    height: 100%;
    background-image: url('../../../../public/images/dropia_logo.png');
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0px 100px 0px 10px;
    cursor: pointer;
}

.Header-container .menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 100%;
    cursor: pointer;
}

.Header-container .link {
    width: 100%;
    height: 100%;
}

.Header-container .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 100%;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: var(--default-text-color)
}

.Header-container .menu:hover {
    font-weight: 700;
    color: var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
}

.Header-container .menu.active {
    font-weight: 700;
    color: var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
}

.Header-container .header-spacer {
    width: 30%;
}

.Header-container .sub-menu-container {
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

.Header-container .login-button{
    width: auto;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    color: var(--color-primary);
    white-space:nowrap;
}

.Header-container .login-button:hover {
    font-weight: 700;
    text-decoration: underline;
}

.Header-container .submenu-button {
    cursor: pointer;
}

.Header-container .submenu-button.none {
    display: none;
}

.Header-container .sub-menu-click-box {
    height: 100%;
}

.Header-container .sub-menu-click {
    min-width: 148px;
    height: 100%;
    letter-spacing: 2px;
    background-color: #FFF;
    z-index: 2;
    position: relative;
    white-space: nowrap;
}

.Header-container .submenu {
    min-width: 148px;
    position: absolute;
    top: 50px;
    background-color: #FFF;
    border: 1px solid var(--default-text-color-hint);
    padding: 10px 0;
    top: -250px;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: top 0.3s ease-in-out;
}

.Header-container .submenu.active {
    top: 50px; 
}
  
.Header-container .submenu .part-divider {
    margin: 10px;
}

.Header-container .submenu-text {
    font-weight: 500;
    font-size: 16px;
    color: var(--default-text-color);
    padding: 10px;
    cursor: pointer;
}

.Header-container .submenu-text:hover {
    background-color: var(--color-primary);
    color: #FFF;
}

.Header-container .sub-menu-container .alarm-box {
   margin-right: 15px;
   cursor: pointer;
   transition: background-color 0.3s ease-out;
   padding: 5px;
   border-radius: 10px;
}

/* .Header-container .sub-menu-container .alarm-box:hover {
    background-color: var(--color-primary-2);
    color: white;
} */

.Header-container .sub-menu-container .fa-bell {
    padding: 5px 7px;
    border-radius: 50px;
    /* width: 25px;
    height: 25px; */
    /* transition: background-color 0.2s ease-out; */
}

.Header-container .sub-menu-container .fa-bell:hover {
    background-color: var(--color-primary-2);
    color: white;
}

.Header-container .sub-menu-container .new-alarm {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #FFB8B8;
}

/* .Header-container .login-button .user-logo {
    padding: 5px;
    border: 1px solid #EEEEEE;
    border-radius: 100px;
    margin-right: 5px;
} */

.Header-container .login-button .user-logo-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border: 2px solid #EEE;
    border-radius: 50px;
}

.Header-container .login-button .user-logo-img.none {
    background-image: url("../../../../public/images/User-default-Image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.Header-container .sub-menu-click .user-logo-img {
    /* width: 40px;
    height: 40px; */
}

.Header-container .appinfo {
    /* position: absolute;
    right: 0; */
    margin: 0 20px 0 0;
    font-size: 14px;
}