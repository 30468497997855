.dropia-reg .my-profile-box {
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
}

.dropia-reg .profile-intro {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    padding: 0 10px;
}

.dropia-reg .my-profile-box .progress-box.user-info {
    position: relative;
    justify-content: flex-start;
    /* width: 100%; */
    /* height: 100%; */
    /* flex: none; */
    background-color: #FFFFFF;
    padding: 0;
}

.dropia-reg .progress-box {
    /* margin: 0; */
}

.dropia-reg .user-info .my-profile-tag:first-child {
    margin-left: 0;
}

.dropia-reg .user-info .my-profile-tag {
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    background-color: #FFFFFF;
    margin: 0 5px 5px 0;
    cursor: default;
}

.dropia-reg .user-info .my-profile-tag:hover {
    color: var(--color-secondary);
    background-color: #FFFFFF;
}

.dropia-reg .my-profile-box .privacy-box label {
    flex-basis: auto;
    margin: 0;
}

.dropia-reg .user-info .my-profile-tag-box {
    flex-wrap: wrap;
}

.dropia-reg .my-profile-box .privacy-box {
    min-width: 85px;
    justify-content: space-evenly;
    /* justify-content: space-between; */
    margin: 0 10px;
}

.dropia-reg .my-profile-box .privacy-box .info-btn.privacy .privacy {
    /* margin-bottom: 5px; */
    /* margin-left: 0; */
}

.dropia-reg .my-profile-box .privacy-box .privacy-row .info-btn.privacy.years {
    font-weight: 400;
}

.dropia-reg .my-profile-box .privacy-box .privacy-row .info-btn.privacy.years {
    margin-right: 5px;
}

/* 내 프로필 보기 coTy 버튼 hover */
.dropia-reg .dropia-reg-main .info-btn.privacy:hover {
    background-color: var(--color-gray-1);
}
/*  */

.dropia-reg .my-profile-box .progress-box {
    min-width: 200px;
    /* max-height: 70px; */
}

.dropia-reg .my-profile-box .progress-box.user-info p {
    margin-bottom: 10px;
}

.dropia-reg .my-profile-box .progress-box p {
    margin-bottom: 0;
}

.dropia-reg .my-profile-box .progress-box .sub-title {
    margin-bottom: 5px;
}

/* 평점 */
.dropia-reg .rating-box.my-profile {
    justify-content: flex-end;
    /* margin-bottom: 15px; */
    margin-bottom: 20px;
}

.dropia-reg .rating-box .star-rating-box .fa-star {
    /* font-size: 20px; */
}

.dropia-reg .rating-box p {
    margin-left: 10px;
}

.dropia-reg .rating-box .rating {
    color: var(--color-gray-1);
}

.dropia-reg .rating-box .peoplee-num {
    margin-left: 30px;
    font-size: 14px;
    font-weight: 400;
}

.dropia-reg .rating-factors-box .rating-factors {
    /* min-height: 30px; */
    /* justify-content: center; */
    margin-bottom: 15px;
    /* width: 100%; */
}

.dropia-reg .rating-factors-box label {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: auto;
    width: 52px;
    /* flex-basis: 52px; */
    flex-shrink: 0;
    margin: 0 5px 0 15px;
    /* margin-left: 10px;
    margin-right: 15px; */
    /* flex-grow: 1; */
    line-height: 20.27px;
}

.dropia-reg .rating-factors-box label:last-child {
    width: auto;
    margin-right: 0;
}

.dropia-reg .rating-factors-box .bar-chart {
    background-color: #D9D9D9;
    /* background-color: #FBB01F; */
    /* width: 130px; */
    /* width: 170px; */
    height: 8px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.dropia-reg .dropia-reg-main .act-info .info-input:first-child {
    /* margin-left: 10px; */
}

.dropia-reg .dropia-reg-main .act-info.history label:last-child {
    margin-left: 0;
}

.dropia-reg .my-profile-box .progress-box.user-info .result-info {
    margin-bottom: 0px;
}