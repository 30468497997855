.partnerprofile-container {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    justify-content: center;
    background-color: var(--color-gray-3);
    /* height: 100%; */
    
    width: 100%;
    /* height: 100%; */
    background-color: #EEEEEE;
}

.partnerprofile-container .partnerprofile-reg{

}

.partnerprofile-main {
    /* background-color: #FFF;
    width: 860px;
    /* height: 980px; 
    margin: 10px;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 3px 3px 3px 3px #dadce0; */

    width: 860px;
    /* height: 100%; */
    /* max-height: 800px; */
    /* overflow-y: auto; */
    background-color: #FFFFFF;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    box-sizing: border-box;
    padding: 30px 40px;
}

.partnerprofile-main .header-text {
    /* font-size: 18px; */
    margin: 10px 0;
}

.partnerprofile-main .my-profile-box {
    justify-content: space-between;
    padding: 10px;
}

.partnerprofile-main .my-profile-box .user-nm {
    font-size: 16px;
    font-weight: 700;
    margin: 10px;
}

.partnerprofile-main .my-profile-box .user-logo-img {
    margin: 0 20px 0 0;
    border: 2px solid #EEE;
    border-radius: 50px;
}

.partnerprofile-main .my-profile-box .user-logo-img.null {
    background-image: url("../../../public/images/User-default-Image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.partnerprofile-main .my-profile-box .user-info .my-profile-tag-box {
    flex-wrap: wrap;
}

.partnerprofile-main .my-profile-box .my-profile-tag {
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    font-weight: 700;
    margin: 5px;
}

.partnerprofile-main .my-profile-box .privacy-box {
    /* width: 85px; */
    margin: 0 10px;
    justify-content: space-evenly;
}

.partnerprofile-main .my-profile-box .privacy-box .privacy {
    background-color: var(--color-gray-1);
}

.partnerprofile-main .my-profile-box .progress-box {
    font-weight: 700;
    width: 200px;
    height: 80px;
    background-color: #F6F6F6;
    border-radius: 2px;
    margin: 10px;
    padding: 10px 14px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.partnerprofile-main .my-profile-box .progress-box .prj-result {
    font-size: 24px;
    color: #3F6BCC;
}


.partnerprofile-main .profile-intro {
    padding: 10px;
    height: 80px;
}


.partnerprofile-main .rating-container {
    width: 560px;
}

.partnerprofile-main .rating-container .rating-factors {
    margin: 10px 0;
}

.partnerprofile-main .rating-container .rating-factors label{
    width: 70px;
}

.partnerprofile-main .rating-container .bar-chart {
    height: 8px;
    width: 160px;
}

.partnerprofile-main .rating-container .bar-chart.rotate {
    width: 8px;
    height: 80px;
}

.partnerprofile-main .rating-container .overall-rating-factors {
    width: 50px;
}

.partnerprofile-main .portfolio-box {
    width: 200px;
    height: 300px;
    margin: 20px;
}

.partnerprofile-main .portfolio-box .portfolio-img-img {
    width: 200px !important;
    height: 200px !important;
    background-size: 100%;
    border: 1px solid #EEE;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
}

.partnerprofile-main .portfolio-box .text {
    width: 100%;
}
.partnerprofile-main .portfolio-box .text.prtfolio_name {
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0;
}   

.partnerprofile-main .portfolio-box .text.prtfolio_type {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: end;
}  

.partnerprofile-main .portfolio-box .text.prtfolio_date {
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0;
    display: flex;
    justify-content: end;
}  

.partnerprofile-main .empty-content {
    padding: 10px;
    height: 80px;
}

/* 추가 */
.partnerprofile-container .my-profile-box .privacy-box .privacy-row .info-btn.privacy.years {
    margin-right: 5px;
}

.partnerprofile-container .portfolio-list {
    /* justify-content: flex-start;
    flex-wrap: wrap; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding : 0 10px;
    /* overflow-y: auto; */
}
