
.product-reg-modal-container {
  font-family: 'Noto Sans KR' !important;
  top: 10%;
  left: calc(50% - (800px/2));
  width: 800px;
  height: 1000px;
  padding: 20px;
  max-height: 80%;
  overflow-y: auto;
}
  
.product-reg-modal-container form {
  width: 100%;
}
  
.product-reg-modal-container .product-reg.header-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 5px;
  height: 27px;
  width: 55px;
}
  
.product-reg-modal-container .product-reg-input {
  font-size: 14px;
  width: 200px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid var(--default-text-color-hint);
  outline: none;
  padding: 5px;
  height: 27px;
}

.product-reg-modal-container .product-reg-input .product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px 0 0;
}

.product-reg-modal-container .product-reg-input.ui.selection.dropdown {
  border: 1px solid var(--default-text-color-hint);
  width: 200px;
}

.product-reg-modal-container textarea.product-reg-input  {
  height: 90px;
}

.product-reg-modal-container .ui.checkbox input.hidden+label {
  font-size: 14px;
}

.product-reg-modal-container .product-reg-checkbox-group {
  width: 200px;
  margin: 10px;
  outline: none;
  padding: 0 20px 0 10px;
  height: 20px;
}

.product-reg-modal-container .product-viewer {
  width: 450px;
  height: 360px;
  /* margin: 10px; */
  border: 1px solid var(--default-text-color-hint);
  border-radius: 5px;
  color: var(--default-text-color-hint);
}
  
.product-reg-modal-container .product-map {
  border: 1px solid var(--default-text-color-hint);
  border-radius: 5px;
  width: 100%;
  height: 420px;
  margin: 10px;
  position: relative;
}
  
.product-reg-modal-container .product-map .geobutton {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FFF;
  width: 40px;
  height: 25px;
  border-radius: 5px;
  font-size: 14px;
  margin: 10px;
  color: var(--default-text-color);
  cursor: pointer;
}

.product-reg-modal-container .product-map .geobutton.marker {
  right: 7%;
}

.product-reg-modal-container .product-map .geobutton.active {
  background-color: var(--color-primary);
  color: #FFF;
}

.product-reg-modal-container .product-map .geobutton:hover {
  background-color: var(--color-primary);
  color: #FFF;
}

.product-reg-modal-container .button {
  width: 140px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  border: 1px solid var(--color-secondary);
}
  
.product-reg-modal-container .button.reg {
  background-color: var(--color-secondary);
  color: #FFF;
}

.product-reg-modal-container .button.reg:hover {
  background-color: var(--color-secondary-p2);
}

.product-reg-modal-container .button.cancel {
  color: var(--color-secondary);
}

.product-reg-modal-container .button.cancel:hover {
  background-color: var(--color-secondary-p2);
  color: #FFF;
}