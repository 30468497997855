.Project-container {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    justify-content: center;
    background-color: var(--color-gray-3);
    /* height: 100%; */
}

.project-view {
    display: flex;
    justify-content: center;
    /* height: 100%; */
}

.project-view .project-view-list {
    height: 100%;
    margin: 10px;
}

.project-view .project-view-list .list-header {
    width: 100%;
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;
}

.project-view .project-view-list .list-header .header-text {
    height: 55px;
    display: flex;
    align-items: center;
}

.project-view .project-view-list .list-header .header-text .main-text {
    font-weight: 600;
    font-size: 18px;
}

.project-view .project-view-list .list-header .header-text .sub-text {
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 0 10px;
}

.project-view .project-view-list .list-header .sort-button {
    background-color: #FFF;
    padding: 10px;
    height: 35px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    cursor: pointer;
}

.project-view .project-view-list .list-header .sort-button:hover {
    border: 2px solid var(--default-text-color-hint)
}

.project-view .project-view-list .list-header .sort-button .sort-image {
    background-image: url("../../../public/images/arrow-down-wide-short-solid.svg");
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 15px;
}

.project-view .project-view-list .list-header .sort-button .sort-text {
    font-weight: 600;
    font-size: 14px;
}

.project-view .project-view-list .list-header .sort-button .sort-text.active {
    margin: 0 5px;
    padding: 5px;
}

.project-view .project-view-list .list-header .sort-button .sort-text.active:hover {
    background-color: var(--color-primary-1);
    color: #FFF;
    border-radius: 10px;
}

.project-view .project-view-list .list-main {
    width: 860px;
}

.project-view .project-view-list .project-summary-box {
    background-color: #FFF;
    width: 860px;
    height: 180px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    cursor: default;

    opacity: 0;
    -webkit-animation: fadein-moveleft 1.0s;
    animation-fill-mode: forwards;
}

.project-view .project-view-list .project-summary-box:hover {
    border: 1.5px solid var(--color-primary);
}

.project-view .project-view-list .project-summary-box .part-main {
    min-width: 500px;
}

.project-view .project-view-list .project-summary-box .part-sub {
    width: 200px;
}

.project-view .project-view-list .project-summary-box .box {
    font-weight: 700;
    font-size: 14px;
    width: 64px;
    height: 22px;
    border-radius: 5px;
    margin: 5px 10px 5px 0;
}

.project-view .project-view-list .project-summary-box .box.recruit {
    color: #FFF;
    border: 1px solid var(--color-secondary-1);
    background-color: var(--color-secondary-1);
}

.project-view .project-view-list .project-summary-box .box.recruit.end {
    border: 1px solid var(--color-gray-2);
    background-color: var(--color-gray-2);
}

.project-view .project-view-list .project-summary-box .box.new {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.project-view .project-view-list .project-summary-box .reg-date {
    font-weight: 500;
    font-size: 12px;
}

.project-view .project-view-list .project-summary-box .option-icon {
    width: 20px;
    margin: 5px 10px;
    color: #999;
}

.project-view .project-view-list .project-summary-box .project-name {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    margin: 10px 10px 10px 0;
}

.project-view .project-view-list .project-summary-box .project-option.textA {
    font-weight: 400;
    font-size: 14px;
    color: var(--default-text-color-hint);
    width: 55px;
    display: flex;
    align-items: center;
}

.project-view .project-view-list .project-summary-box .project-option.textB {
    font-weight: 700;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 5px;
    /* width: 60px; */
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.project-view .project-view-list .project-summary-box .project-option.textC {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-secondary-p1);
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
}

.project-view .project-view-list .project-summary-box .project-option.textC.none {
    color: var(--default-text-color-hint);
}

.project-view .project-view-list .project-summary-box .project-option.textD {
    color: var(--default-text-color-hint);
    font-weight: 400;
    font-size: 14px;
    margin: 10px 10px 10px 0;
    width: 60px;
}

.project-view .project-view-list .project-summary-box .project-option.textE {
    font-weight: 700;
    font-size: 14px;
    margin: 10px 5px;
    width: 80px;
}

.project-view .project-view-list .project-summary-box .project-option.textE.red {
    color: var(--color-red)
}

.project-view .project-view-list .project-summary-box .fs-icon-clipboard {
    color: var(--color-secondary-p1);
    margin-left: 15px;
}

.project-view .project-view-list .project-summary-box .fs-icon-clipboard.none {
    color: var(--default-text-color-hint);
}

.project-view .project-view-list .project-summary-box .client-mark {
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    border: 2px solid #EEE;
    border-radius: 15px;
}

.project-view .project-view-list .project-summary-box .client-mark.null {
    background-image: url('../../../public/images/User-default-Image.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}


.project-view .project-view-list .project-summary-box .apply-button {
    font-weight: 600;
    font-size: 16px;
    width: 122px;
    height: 31px;
    border-radius: 5px;
    color: #FFF;
    background-color: var(--color-secondary);
    margin: 10px 30px 0 30px;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
}

.project-view .project-view-list .project-summary-box .apply-button:hover {
    background-color: var(--color-secondary-p2);
}

.project-view .project-view-list .project-summary-box .project-location {
    font-weight: 400;
    font-size: 14px;
    margin: 10px;
    display: flex;
    align-items: center;
}

.project-view .project-view-list .project-summary-box .project-type {
    font-weight: 400;
    font-size: 12px;
    background-color: #FF6262;
    color: #FFF;
    border-radius: 5px;
    padding: 0 5px;
    height: 20px;
    margin: 10px;
}

.project-view .project-view-list .project-summary-box .client-mark {

}

.project-view .project-view-list .project-summary-box .client-name {
    font-weight: 400;
    font-size: 14px;
}

.project-view .project-view-list .project-summary-box .small-divider {
    /* margin: 10px 10px 10px 5px; */
    margin: 10px;
}

.project-view .project-view-list .project-summary-box .part-divider {
    width: 1px;
    height: 140px;
    margin: 5px 40px;
}

.project-view .project-view-list .pagination{
    width: 500px !important;
    height: 35px;
    margin: 20px 0 !important;
    /* position: absolute; */
    /* bottom: 5%; */
}

.project-view .project-view-filter {
    min-width: 325px;
    /* height: 650px; */
    margin: 10px;
    border-radius: 10px;
    box-sizing: border-box;
}

.project-view .project-view-filter .filtering-option {
    background-color: #FFF;
    width: 100%;
    /* height: 700px; */
    margin: 75px 0 0 0;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #DADCE0;
}

.project-view .project-view-filter .filtering-option .filtering-header {
    width: 100%;
    height: 40px;
    background-color: var(--color-primary);
    border-radius: 5px 5px 0 0;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.project-view .project-view-filter .filtering-option .filtering-header .text {
    font-weight: 700;
    font-size: 16px;
    color: #FFF;
}


.project-view .project-view-filter .filtering-option .filtering-main .text {
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0;
}

.project-view .project-view-filter .filtering-option .filtering-main .filtering-type {
    padding: 10px 20px;
}

.project-view .project-view-filter .filtering-option .filtering-cost {
    padding: 10px 20px;
}

.project-view .project-view-filter .filtering-option .filtering-cost .cost {
    text-align: center;
    padding: 10px;
    width: 80px;
    height: 30px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    outline: none;
}

.project-view .project-view-filter .filtering-option .filtering-cost .cost.lower {
    margin: 5px 10px 5px 0;
}

.project-view .project-view-filter .filtering-option .filtering-cost .cost.upper {
    margin: 5px 10px;
}

.project-view .project-view-filter .filtering-option .filtering-location {
    padding: 10px 20px;
}

.project-view .project-view-filter .filtering-option .filtering-location .sido-group {
    display: grid;
    width: 325px;
    height: 225px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.project-view .project-view-filter .filtering-option .filtering-factor {
    margin: 10px 0;
}

.project-view .project-view-filter .filtering-option .filtering-factor .checkbox {
    margin: 0 10px 0 0;
    width: 15px;
}

.project-view .project-view-filter .filtering-option .textB {
    font-weight: 500;
    font-size: 16px;
    color: var(--default-text-color);
}

.project-view .button {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    height: 40px;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    color: white;
    background-color: var(--color-primary);
    cursor: pointer;
    margin: 20px 0 0 0;
    transition: background-color 0.3s ease-out;
}

.project-view .button:hover {
    background-color: var(--color-primary-p2);
}