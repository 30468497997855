.proposal-view-detail {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    justify-content: center;
}


.proposal-view-detail .proposal-view-detail-main .partner-summary-box {
    background-color: #FFF;
    width: 860px;
    height: 180px;
    margin: 10px 0;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    cursor: default;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .partner-logo {
    width: 100px;
    height: 100px;
    margin: 30px 0;
    border: 2px solid #EEE;
    border-radius: 50px;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .partner-logo.null {
    background-image: url("../../../public/images/User-default-Image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main {
    padding: 20px 30px;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .partner-name {
    font-weight: 700;
    font-size: 20px;
    color: var(--color-primary);
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .partner-type {
    font-weight: 500;
    font-size: 15px;
    color: var(--default-text-color-hint);
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .partner-certification {
    font-weight: 500;
    font-size: 15px;
    color: var(--default-text-color-hint);
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .fa-building {
    color: var(--default-text-color);
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .fa-award {
    color: #F95632;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .fa-heart {
    color: #FF3131;
    margin: 0 10px;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .partner-detail {
    font-weight: 700;
    font-size: 14px;
    color: var(--color-secondary-p1);
    margin: 10px 0;
    border: 2px solid var(--color-secondary-p1);
    border-radius: 5px;
    width: 70px;
    height: 25px;
    cursor: pointer;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .partner-detail:hover {
    background-color: var(--color-secondary-p1);
    color: #FFF;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-main .partner-introduce {
    font-weight: 400;
    font-size: 14px;
    width: 450px;
    line-height: 20px;
    color: var(--default-text-color);
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-sub {
    display: flex;
    justify-content: center;
    width: 200px;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-sub .score {
    margin: 10px 0;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .part-sub .score .text {
    font-weight: 600;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 0 0 35px;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .partner-option.textA {
    font-weight: 500;
    font-size: 14px;
    color: var(--default-text-color-hint);
    width: 120px;
    display: flex;
    align-items: center;
    margin: 10px 10px 10px 0;
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .partner-option.textB {
    font-weight: 600;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 5px;
    width: 60px;
    display: flex;
    align-items: center;
    
}

.proposal-view-detail .proposal-view-detail-main .partner-summary-box .partner-option.textC {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-secondary-p1);
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
}



.proposal-view-detail .proposal-abstract {
    width: 860px;
    margin: 10px;
}

.proposal-view-detail .proposal-abstract .project-apply {
    width: 100%;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    padding: 20px 30px;
}

.proposal-view-detail .proposal-abstract .project-apply .proposal-input {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 14px;
    width: 60px;
    border: none;
    outline: none;
    height: 35px;
    text-align: center;
}

.proposal-view-detail .proposal-abstract .project-apply .contents-input {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    white-space: pre-wrap;
    width: 100%;
    height: 300px;
    margin: 20px 0;
    padding: 10px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    resize: none;
    overflow-y: auto;
}

.proposal-view-detail .proposal-abstract .project-apply .portfolio-group {
    margin: 20px 0;
}

.proposal-view-detail .proposal-abstract .project-apply .portfolio-group .portfolio {
    font-weight: 700;
    color: var(--color-secondary);
    width: 200px;
    height: 200px;
    border: 1px dashed var(--color-secondary);
    border-radius: 5px;

}


.proposal-view-detail .proposal-abstract .option {
    height: 40px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    margin: 20px 20px 20px 0;
    padding: 5px;
    box-sizing: border-box;
}

.proposal-view-detail .proposal-abstract .option-icon {
    color: #DDD;
    font-size: 12px;
    width: 15px;
    height: 35px;
    margin: 0 0 0 10px;
}

.proposal-view-detail .proposal-abstract .option-name {
    font-weight: 600;
    font-size: 14px;
    height: 35px;
    color: var(--default-text-color-hint);
    border: none;
    display: flex;
    align-items: center;
    margin: 0 20px 0 20px;
}

.proposal-view-detail .proposal-abstract .option-input {
    font-weight: 400;
    font-size: 14px;
    width: 300px;
    height: 35px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
}

.proposal-view-detail .proposal-abstract .sub-text {
    font-weight: 700;
    font-size: 14px;
    margin: 0 20px 0 20px;
}

.proposal-view-detail .proposal-view-detail-sub .button-group {
    background-color: #FFF;
    width: 320px;
    /* height: 240px; */
    margin: 10px 0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 3px 3px 3px 3px #dadce0;
}

.proposal-view-detail .proposal-view-detail-sub .meeting-management {
    background-color: #FFF;
    width: 320px;
    height: 600px;
    margin: 10px 0;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 3px 3px 3px 3px #dadce0;
}

.proposal-view-detail .proposal-view-detail-sub .meeting-management .content-text {
    margin: 10px 0;
}

.proposal-view-detail .proposal-view-detail-sub .meeting-management .memo-textarea {
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    margin: 10px 0;
    height: 260px;
    outline: none;
    padding: 10px;
}

.proposal-view-detail .proposal-view-detail-sub .meeting-management .meeting-date {
    font-size: 14px;
    width: 150px;
    height: 30px;
    margin: 2.5px 0;
    border-radius: 5px;
    border: 1px solid var(--default-text-color-hint);
}


.proposal-view-detail .proposal-view-detail-sub .contract-management {
    background-color: #FFF;
    width: 320px;
    height: 200px;
    margin: 10px 0;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 3px 3px 3px 3px #dadce0;
}


.proposal-view-detail .proposal-view-detail-sub .button {
    font-weight: 700;
    font-size: 16px;
    width: 284px;
    height: 47px;
    border-radius: 5px;
    margin: 5px 0;
    cursor: pointer;
}

.proposal-view-detail .proposal-view-detail-sub .button.back {
    background-color: var(--color-gray-1);
    color: #FFF;
}

.proposal-view-detail .proposal-view-detail-sub .button.back:hover {
    background-color: var(--color-gray);
}

.proposal-view-detail .proposal-view-detail-sub .button.except {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.proposal-view-detail .proposal-view-detail-sub .button.except:hover {
    background-color: var(--color-primary-p2);
    color: #FFF;
}

.proposal-view-detail .proposal-view-detail-sub .button.inter {
    background-color: #FFF;
    color: var(--default-text-color);
    border: 1px solid var(--color-red);
}

.proposal-view-detail .proposal-view-detail-sub .button.inter:hover {
    border: 3px solid var(--color-red);
}

.proposal-view-detail .proposal-view-detail-sub .fa-heart {
    color: #FF3131;
    margin: 0 10px;
}

.proposal-view-detail .proposal-view-detail-sub .button.meeting {
    background-color: var(--color-primary);
    color: #FFF;
}

.proposal-view-detail .proposal-view-detail-sub .button.meeting:hover {
    background-color: var(--color-primary-p2);
}

.proposal-view-detail .proposal-view-detail-sub .button.meeting.done {
    background-color: var(--color-gray-2);
    color: #FFF;
    cursor: default;
}

.proposal-view-detail .proposal-view-detail-sub .button.cancel {
    background-color: var(--color-gray-1);
    color: #FFF;
}
    
.proposal-view-detail .proposal-view-detail-sub .button.cancel:hover {
    background-color: var(--color-gray);
}

.proposal-view-detail .proposal-view-detail-sub .button.save {
    background-color: var(--color-tertiary);
    color: #FFF;
}

.proposal-view-detail .proposal-view-detail-sub .button.save:hover {
    color: var(--color-tertiary);
    background-color: #FFF;
    border: 1px solid var(--color-tertiary);
}

.proposal-view-detail .proposal-view-detail-sub .contract-management .button.save {
    margin: 20px 0 0 0;
}

.proposal-view-detail .proposal-view-detail-sub .ui.selection.dropdown {
    border: 1px solid var(--default-text-color-hint);
    padding: 0 0 0 10px;
    width: 150px;
    height: 30px;
    margin: 2.5px 0;
}