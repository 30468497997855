.Login-container > *,
.Login-container .input-container .login.pw {
    font-family: 'Noto Sans KR';
    color: var(--color-gray-1);
}

.Login-container {
    position: absolute;
    top: 150px;
    left: calc(50% - (418px / 2));
    /* transform: translate(-50%, -50%); */
    width: 418px;
    border-radius: 10px; 
    box-shadow: 3px 3px 3px 3px #dadce0;
    padding: 30px; 
    box-sizing: border-box;
    flex: 1;
}

.Login-container > .login-text,
.Login-container > .login,
.Login-container > .input-container,
.Login-container > .sb,
.Login-container > label,
.Login-container > .sns-login,
.Login-container .choose-user-type .user-type-box {
    margin-bottom: 15px; 
}

.Login-container > .input-container.checkpw-box {
    margin-bottom: 0px;
}

.Login-container .login-text {
    align-self: flex-start; 

    color: #000000;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.Login-container .login {
    padding: 9.5px 8px;
    width: 100%;
    
    box-sizing: border-box; 
    border: 1px solid #D0D0D0;
    border-radius: 4px;

    outline: none;
    
    /* font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left; */
}

.Login-container .input-container {
    width: 100%;
    position: relative;
    display: inline-block;
    font-family: 'Noto Sans KR';
}

.Login-container .login {
    max-height: 40px;
    height: 100%;
}


.Login-container .input-container .login.pw {
    /* padding: 11.5px 8px; */
    padding-right: 30px;
    /* font-family: 'Noto Sans KR'; */
}

.Login-container .input-container .showPwIcon {
    width: 20px;
    height: 20px;

    position: absolute;
    top: 50%;
    transform: translateY(-35%);
    right: 8px;

    cursor: pointer;
}

.Login-container .flex-row {
    /* display: flex;
    justify-content: center; */
}

.Login-container .sb {
    width: 100%;
    justify-content: space-between;
}

.Login-container .sb .stay-login:hover {
    cursor: pointer;
}

.Login-container .sb label {
    margin-left: 5px;
    cursor: pointer;
}

.Login-container .sb .find-pw:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Login-container label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.Login-container .login-btn {
    font-weight: 700;
    font-size: 16px;
    /* letter-spacing: 1px; */
    width: 100%;
    padding : 12px 14px;
    box-sizing: border-box;
    margin: 10px 0 ;
    border-radius: 10px;
    /* font-size: 18px;
    font-weight: 700; */
    cursor: pointer;
    transition: background-color 0.3s ease-out;
    color: #ffffff;
}

.Login-container .hr-solid {
    width: 100%;
    border-top: 1px solid #B7B7B7;
    margin: 20px 0px;
}

.Login-container .sns-login {
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    /* height: 35px; */
    /* padding: 10px; */

    font-weight: 700;
    font-size: 16px;
    /* letter-spacing: 1px; */

    /* display: flex;
    align-items: center; */
    position: relative;
    transition: background-color 0.3s ease-out;
}

.Login-container .sns-login:hover {
    cursor: pointer;
}

.Login-container .sns-login .symbol {
    position: absolute;
    left: 0;
}

.Login-container .sns-login.kakao {
    /* min-height: 35px; */
    /* padding: 0 9px; */
    background-color: #FEE500;
    /* border-radius: 5px; */
    color: #00000085;
    /* font-size: 12px; */
}

.Login-container .sns-login.kakao:hover {
    background-color: #e9d202;
}

.Login-container .kakao-symbol {
    width: 28px;
    height: 28px;
	background: url('../../../public//icons/kakao.svg') no-repeat center;
    background-position: center;
    background-size: 70%;
    margin: 0 10px;
}

.Login-container .sns-login.naver {
    /* min-height: 35px; */
    /* padding: 0 12px 0 5px; */
    background-color: #03C75A;
    /* border-radius: 5px; */
    color: #ffffff;
    /* font-size: 12px; */
}

.Login-container .sns-login.naver:hover {
    background-color: #00ac4d;
}

.Login-container .naver-symbol {
    width: 28px;
    height: 28px;
	background: url('../../../public/icons/naver.svg') no-repeat center;
    background-position: center;
    background-size: 100%;
    /* box-sizing: border-box; */
    margin: 0 10px
}

.Login-container .sns-login.google {
    /* min-height: 35px; */
    /* padding: 0 8px 0 6px; */
    background-color: #ffffff;
    border: 0.8px solid #00000060;
    /* border-radius: 5px; */
    color: #00000085;
    /* font-size: 12px; */

    /* font-family: "Roboto", sans-serif; */
    /* font-weight: 500; */
}

.Login-container .sns-login.google:hover {
    background-color: #e4e2e2;
}

.Login-container .google-symbol {
    width: 28px;
    height: 28px;
	background: url('../../../public/icons/google.svg') no-repeat center;
    background-position: center;
    background-size: 70%;
    margin: 0 10px
}

.Login-container .sns-text {
    width: 100%;
    text-align: center;
    flex: 1;
    position: absolute;
}

.Login-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.Login-container p:first {
    margin-bottom: 15px;
}

.Login-container .dropia {
    font-weight: 700;
    color: #487FF9;
    letter-spacing: 1px;
}

.Login-container .signup {
    font-weight: 700;
    color: var(--color-primary);
}

.Login-container .signup:hover {
    text-decoration: underline;
}

/* 회원가입 */
.Login-container.sign-up {
    /* top: 200px; */
}

.Login-container .sb.sign-up-center {
    justify-content: center;
}

/* Caps Lock */
.Login-container .sb.sign-up-center.sub-msg {
    margin: 5px 0;
}

.Login-container .sb.sign-up-center.sub-msg.pw {
    margin: 0 0 5px 0;
}

.Login-container .border {
    /* width: 100; */
    border-radius: 5px;
    border: 1px solid var(--color-gray-1) !important;
}

.Login-container .choose-user-type {
    justify-content: space-between;
}

.Login-container .choose-user-type .user-type-box {
    /* flex: 1; */
    /* box-sizing: border-box; */
    max-width: 170px;
    padding: 15px;
    border: 1px solid var(--color-gray-2) !important;
    background-color: var(--color-gray-3);
    /* flex-wrap: wrap; */
}

.Login-container .choose-user-type .user-type-box:hover {
   cursor: pointer;
}

.Login-container .choose-user-type .user-type-box.selected {
    color: #FFFFFF;
    background-color: #81A8FF;
}

.Login-container .choose-user-type .user-type-box span {
    /* font-family: Noto Sans KR; */
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    /* line-height: 20.27px; */
    /* text-align: left; */
}


.Login-container .choose-user-type .user-type-box .user-type-info {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.Login-container {
    cursor: default	
}

/* 네이버로 로그인 */
#naverIdLogin{
    display: none;
}