.App {
  width: 1920px;
  height: 1080px;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  overscroll-behavior-y: none;
}

main {
  height: calc(100vh - 50px);
}

form {
  height: 100%;
}

.service-preparing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.service-preparing .image {
  width: 400px;
  height: 400px;
  background-image: url('../public/images/preparing.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin: 20px;
}

.service-preparing .comment {
  font-family: 'Noto Sans KR';
  font-weight: 1000;
  font-size: 28px;
  margin: 20px;
  color: var(--color-primary);
}

.service-preparing .comment-sub {
  font-family: 'Noto Sans KR';
  font-weight: 100;
  font-size: 18px;
  margin: 5px;
  color: var(--default-text-color);
}