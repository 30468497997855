
.product-detail-modal-container {
  font-family: 'Noto Sans KR' !important;
  top: 10%;
  left: calc(50% - (1250px/2));
  width: 1250px;
  height: 850px;
  padding: 20px;
}

.product-detail-modal-container form{
  width: 100%;
}

.product-detail-modal-container .product-reg.header-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 10px 5px;
  height: 27px;
  width: 55px;
}
  
.product-detail-modal-container .product-reg-input {
  font-size: 14px;
  width: 200px;
  height: 27px;
  margin: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid var(--default-text-color-hint);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}

.product-detail-modal-container .product-reg-input.dropdown {
  overflow: unset;
}

.product-detail-modal-container .product-reg-input.long {
  height: 350px;
  padding: 5px;
  white-space: pre-wrap;
}

.product-detail-modal-container .product-reg-input.ui.selection.dropdown {
  border: 1px solid var(--default-text-color-hint);
  width: 200px;
}

.product-detail-modal-container .product-reg-checkbox-group {
  width: 200px;
  margin: 10px;
  outline: none;
  padding: 0 20px 0 10px;
  height: 27px;
}

.product-detail-modal-container .product-viewer {
  width: 900px !important;
  height: 680px !important;
  margin: 10px;
  border: 1px solid var(--default-text-color-hint);
  border-radius: 5px;
  color: var(--default-text-color-hint);
  position: relative;
}

.product-detail-modal-container .product-viewer .url {
  margin: 10px;
  cursor: pointer;
  color: var(--color-primary);
}

.product-detail-modal-container .product-viewer .url:hover {
  text-decoration: underline;
}

.product-detail-modal-container .product-viewer .plus-button:hover {
  background-color: var(--color-primary-p2);
}

.product-detail-modal-container .button {
  font-weight: 600;
  width: 140px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  border: 1px solid var(--color-primary);
}
  
.product-detail-modal-container .button.modify {
  background-color: #FFF;
  color: var(--color-primary)
}

.product-detail-modal-container .button.modify:hover {
  background-color: var(--color-primary-p2);
  color: #FFF;
}

.product-detail-modal-container .button.delete {
  background-color: #FFF;
  color: var(--color-red);
  border: 1px solid var(--color-red);
}

.product-detail-modal-container .button.delete:hover {
  background-color: var(--color-red);
  color: #FFF;
}

.product-detail-modal-container .button.download {
  background-color: #FFF;
  color: var(--color-green);
  border: 1px solid var(--color-green);
}

.product-detail-modal-container .button.download:hover {
  background-color: var(--color-green);
  color: #FFF;
}

.product-detail-modal-container .button.cancel {
  background-color: var(--color-gray-1);
  color: #FFF;
  border: 1px solid var(--color-gray-1);
}

.product-detail-modal-container .button.cancel:hover {
  background-color: var(--color-gray);
  color: #FFF;
}

.canvas-container {
  background-color: #DCEBF5;
}