.project-view-detail {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    justify-content: center;
}

.project-view-detail.hide {
    display: none;
}

.project-view-detail .project-view-detail-main {
    width: 860px;
    margin: 10px;
    padding: 20px 30px;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
}

.project-view-detail .project-view-detail-main input {
    font-family: 'Noto Sans KR' !important;
    outline: none;
    border: none;
}

.project-view-detail .project-view-detail-main .box {
    font-weight: 600;
    font-size: 14px;
    width: 64px;
    height: 22px;
    border-radius: 5px;
}

.project-view-detail .project-view-detail-main .box.recruit {
    color: #FFF;
    border: 1px solid var(--color-secondary-1);
    background-color: var(--color-secondary-1);
    margin: 5px 10px 5px 0;
}

.project-view-detail .project-view-detail-main .box.recruit.end {
    border: 1px solid var(--color-gray-2);
    background-color: var(--color-gray-2);
}

.project-view-detail .project-view-detail-main .box.new {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    margin: 5px 10px 5px 0;
}

.project-view-detail .project-view-detail-main .box.modify {
    /* width: 50px; */
    color: royalblue;
    margin: 5px 0px 5px 30px;
    cursor: pointer;
    opacity: 0.7;
}

.project-view-detail .project-view-detail-main .box.modify:hover {
    color: #FFF;
    background-color: royalblue;
    transition: color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}

.project-view-detail .project-view-detail-main .box.modify .fa-pen {
    margin: 0 5px;
}

.project-view-detail .project-view-detail-main .box.delete {
    /* width: 50px; */
    color: red;
    margin: 5px 0px 5px 20px;
    cursor: pointer;
    opacity: 0.7;
}

.project-view-detail .project-view-detail-main .box.delete:hover {
    color: #FFF;
    background-color: red;
    transition: color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}

.project-view-detail .project-view-detail-main .box.delete .fa-trash {
    margin: 0 5px;
}

.project-view-detail .project-view-detail-main .box.cancel {
    width: 50px;
    color: var(--default-text-color);
    margin: 5px 0px 5px 20px;
    cursor: pointer;
    opacity: 0.7;
}

.project-view-detail .project-view-detail-main .box.cancel:hover {
    color: #FFF;
    background-color: var(--default-text-color);;
    transition: color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}

.project-view-detail .project-view-detail-main .box.cancel .fa-ban {
    margin: 0 5px;
}


.project-view-detail .project-view-detail-main .reg-date {
    font-weight: 500;
    font-size: 12px;
    margin: 0 0 0 30px;
}

.project-view-detail .project-view-detail-main .project-name {
    font-weight: 700;
    font-size: 16px;
    max-width: 580px;
    height: 40px;
    letter-spacing: 1px;
    margin: 10px 10px 10px 0;
    display: flex;
    align-items: center;
}

.project-view-detail .project-view-detail-main .count-text {
    font-weight: 500;
    font-size: 14px;
    color: var(--default-text-color-hint);
    margin: 0 10px;
}

.project-view-detail .project-view-detail-main .count-num {
    font-weight: 700;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 30px 0 0;
}

.project-view-detail .project-view-detail-main .option {
    width: 200px;
    height: 40px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    margin: 20px 5px;
    padding: 5px;
    box-sizing: border-box;
}

.project-view-detail .project-view-detail-main .option-icon {
    color: #DDD;
    font-size: 12px;
    width: 15px;
    height: 35px;
}

.project-view-detail .project-view-detail-main .option-name {
    font-weight: 400;
    font-size: 14px;
    height: 35px;
    color: var(--default-text-color-hint-1);
    border: none;
    display: flex;
    align-items: center;
    margin: 0 15px;
}

.project-view-detail .project-view-detail-main .option-input {
    font-weight: 400;
    font-size: 14px;
    color: var(--default-text-color);
    width: 140px;
    height: 35px;
    border: none;
    outline: none;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0 10px 0 30px;
}

.project-view-detail .project-view-detail-main .option-input-sub {
    font-weight: 400;
    font-size: 14px;
    height: 35px;
    color: #FF0000;
    display: flex;
    align-items: center;
    margin-left: -60px;
}

.project-view-detail .project-view-detail-main .sub-text {
    font-weight: 700;
    font-size: 14px;
    margin: 0 5px;
}

.project-view-detail .project-view-detail-main .sub-input {
    font-weight: 700;
    font-size: 14px;
    width: 40px;
    text-align: center;
    /* border-bottom: 1px solid var(--default-text-color); */
}

.project-view-detail .project-view-detail-main .ui.selection.dropdown {
    width: 120px;
    height: 35px;
}

.project-view-detail .project-view-detail-main .textA {
    font-weight: 700;
    font-size: 16px;
    margin: 20px 0;
}

.project-view-detail .project-view-detail-main .project-explanation {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    white-space: pre-wrap;
}

.project-view-detail .project-view-detail-main .abstract-button {
    color: var(--color-primary);
    font-weight: 700;
    height: 35px;
    margin: 20px 0 0 0;
    cursor: pointer;
}

.project-view-detail .project-view-detail-main .abstract-button:hover {
    color: var(--color-primary-p2);
}

.project-view-detail .project-view-detail-main .abstract-button .abstract-icon {
    margin: 0 10px;
}



.project-view-detail .project-view-detail-menu {
    width: 860px;
    margin: 10px;
}

.project-view-detail .project-view-detail-menu .project-apply {
    width: 100%;
    border-radius: 5px;
    border-top-left-radius: 0;
    box-shadow: 3px 3px 3px 3px #dadce0;
    padding: 20px 30px;
}

.project-view-detail .project-view-detail-menu .project-apply .proposal-input {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 14px;
    width: 60px;
    border: none;
    outline: none;
    height: 35px;
    text-align: center;
}

.project-view-detail .project-view-detail-menu .project-apply .contents-input {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    white-space: pre-wrap;
    width: 100%;
    height: 300px;
    margin: 20px 0;
    padding: 10px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    resize: none;
    overflow-y: auto;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group {
    margin: 20px 0;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-item {
    position: relative;
    width: 200px;
    height: 300px;
    border: 1px solid #EEE;
    padding: 10px 0;
    margin: 0 20px 0 0;
    border-radius: 5px;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-item-del {
    top: 0;
    right: 0;
    margin: 0;
    border-radius: 10px;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-item-del:hover {
    background-color: #000;
    color: #FFF;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-item-img {
    width: 200px;
    height: 200px;
    margin: 10px 0;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-item-name {
    font-size: 16px;
    text-align: start;
    margin: 5px;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-item-date {
    font-size: 12px;
    text-align: end;
    margin: 5px;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-item-type {
    font-size: 14px;
    text-align: end;
    margin: 5px;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-button {
    font-weight: 700;
    color: var(--color-secondary);
    width: 200px;
    height: 300px;
    border: 1px dashed var(--color-secondary);
    border-radius: 5px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-button:hover {
    border: 2px solid var(--color-secondary);
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-option {
    border: 1px solid var(--default-text-color-hint);
    border-radius: 10px;
    width: 180px;
    height: 35px;
    margin: 0 10px;
    padding: 10px;
    font-size: 12px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .project-apply .portfolio-group .portfolio-option:hover {
    background-color: var(--color-primary);
    color: #FFF;
}

.project-view-detail .project-view-detail-menu .menu-tap {
    font-weight: 700;
    width: 150px;
    height: 40px;
    border-radius: 5px 20px 0 0;
    margin: 0 0 0 -10px;
    cursor: pointer;
    color: var(--color-gray-1);
    background-color: var(--color-gray-3);
    box-shadow: 0px -3px 3px 0 #dadce0;
    z-index: 1;
}

.project-view-detail .project-view-detail-menu .menu-tap.active {
    color: var(--default-text-color);
    background-color: #FFF;
    border-top-left-radius: 5px;
    z-index: 2;
}

.project-view-detail .project-view-detail-menu .menu-tap:first-child {
    margin: 0;
}

.project-view-detail .project-view-detail-menu .menu-container {
    width: 100%;
    height: 1200px;
    display: flex;
    justify-content: flex-start;
}

.project-view-detail .project-view-detail-menu .menu-content {
    width: 100%;
    border-radius: 5px;
    border-top-left-radius: 0;
    box-shadow: 3px 3px 3px 3px #dadce0;
    padding: 20px 30px;
    min-height: 200px;
}

.project-view-detail .project-view-detail-menu .menu-content .header {
    min-height: 51px;
    display: flex;
    align-items: center;
}

.project-view-detail .project-view-detail-menu .menu-content:nth-child(n+2) {
    margin-top: 30px;
}

.project-view-detail .project-view-detail-menu .menu-content .header-text {
    margin: 10px;
}

.project-view-detail .project-view-detail-menu .menu-content .input-textarea {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    height: 200px;
    padding: 5px;
    margin: 10px 0 20px 0;
    outline: none;
}

.project-view-detail .project-view-detail-menu .menu-content .content-text-typeA {
    margin: 10px;
    line-height: 30px;
    letter-spacing: 1px;
}

.project-view-detail .project-view-detail-menu .menu-content .content-text-typeA.modify {
    font-weight: 600;
    font-size: 14px;
    width: 90px;
    height: 22px;
    color: royalblue;
    cursor: pointer;
    opacity: 0.7;
    border-radius: 5px;
    line-height: 22px;
}

.project-view-detail .project-view-detail-menu .menu-content .content-text-typeA.modify .fa-pen {
    margin: 0 5px;
}

.project-view-detail .project-view-detail-menu .menu-content .content-text-typeA.modify:hover {
    color: #FFF;
    background-color: royalblue;
    transition: color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}

.project-view-detail .project-view-detail-menu .partner-summary-box {
    background-color: #FFF;
    width: 800px;
    /* height: 200px; */
    padding: 20px 5px;
    cursor: pointer;
    border-top: 1px solid var(--default-text-color-hint);
}

.project-view-detail .project-view-detail-menu .partner-summary-box:hover {
    border: 2px solid var(--color-primary);
    border-radius: 5px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .header-text {
    height: 20px;
    margin: 0 20px 0px 0;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .contract {
    font-weight: 600;
    font-size: 14px;
    color: #FF5C00;
    height: 20px;
    /* color: #FFF; */
    border: 1px solid #FF5C00;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0 10px 10px 0;
    border-radius: 10px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .contract.off {
    color: var(--default-text-color-hint);
    border: 1px solid var(--default-text-color-hint);;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .contract .fa-handshake,
.project-view-detail .project-view-detail-menu .partner-summary-box .meeting-date .fa-business-time {
    margin: 0 5px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .meeting-date {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-primary);
    height: 20px;
    /* color: #FFF; */
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0 0 10px 0;
    border-radius: 10px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .partner-logo {
    width: 90px;
    height: 90px;
    margin: 25px 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid #EEE;
    border-radius: 45px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .partner-logo.null {
    background-image: url("../../../public/images/User-default-Image.jpg");
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main {
    padding: 10px 20px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .textA {
    font-weight: 500;
    font-size: 14px;
    color: var(--default-text-color-hint);
    margin: 5px 0 10px 0;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .textB {
    font-weight: 700;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 5px 10px 10px 10px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .partner-name {
    font-weight: 700;
    font-size: 20px;
    color: var(--color-primary);
    margin: 10px 0;
    width: 150px;
    display: flex;
    align-items: center;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .partner-type {
    font-weight: 500;
    font-size: 15px;
    color: var(--default-text-color-hint);
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .partner-certification {
    font-weight: 500;
    font-size: 15px;
    color: var(--default-text-color-hint);
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .fa-building {
    color: var(--default-text-color);
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .fa-award {
    color: #F95632;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .fa-heart {
    color: #FF3131;
    margin: 0 10px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .partner-detail {
    font-weight: 700;
    font-size: 14px;
    color: var(--color-secondary-p1);
    margin: 10px 0;
    border: 2px solid var(--color-secondary-p1);
    border-radius: 5px;
    width: 70px;
    height: 25px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-main .partner-introduce {
    font-weight: 400;
    font-size: 15px;
    width: 450px;
    height: 60px;
    line-height: 20px;
    color: var(--default-text-color);
    overflow: hidden;
}


.project-view-detail .project-view-detail-menu .partner-summary-box .part-sub {
    width: 200px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-sub .score {
    margin: 10px 0;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .part-sub .score .text {
    font-weight: 600;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 0 0 30px;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .partner-option.textA {
    font-weight: 500;
    font-size: 14px;
    color: var(--default-text-color-hint);
    width: 120px;
    display: flex;
    align-items: center;
    margin: 10px 10px 10px 0;
}

.project-view-detail .project-view-detail-menu .partner-summary-box .partner-option.textB {
    font-weight: 600;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 5px;
    width: 60px;
    display: flex;
    align-items: center;
    
}

.project-view-detail .project-view-detail-menu .partner-summary-box .partner-option.textC {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-secondary-p1);
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
}

.project-view-detail .project-view-detail-menu .option {
    height: 40px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    margin: 20px 20px 20px 0;
    padding: 5px;
    box-sizing: border-box;
}

.project-view-detail .project-view-detail-menu .payment-info {
    padding: 10px 0;
}

.project-view-detail .project-view-detail-menu .payment-info .button-group {
    justify-content: end;
    align-items: end;
}

.project-view-detail .project-view-detail-menu .payment-info .button-group .contract-info-button {
    width: 120px;
    height: 30px;
    border: 1px solid var(--color-tertiary);
    border-radius: 5px;
    background-color: var(--color-tertiary);
    color: #FFF;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .payment-info .button-group .contract-info-button:hover {
    background-color: #FFF;
    color: var(--color-tertiary);
}

.project-view-detail .project-view-detail-menu .option-icon {
    color: #DDD;
    font-size: 12px;
    width: 15px;
    height: 35px;
}

.project-view-detail .project-view-detail-menu .option-name {
    font-weight: 400;
    font-size: 14px;
    height: 35px;
    color: var(--default-text-color-hint);
    border: none;
    display: flex;
    align-items: center;
    margin: 0 20px 0 20px;
}

.project-view-detail .project-view-detail-menu .option-input {
    font-weight: 400;
    font-size: 14px;
    /* width: 300px; */
    height: 35px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
}

.project-view-detail .project-view-detail-menu .sub-text {
    font-weight: 700;
    font-size: 14px;
    margin: 0 20px 0 20px;
}

.project-view-detail .project-view-detail-menu .project-reg-add {
    height: 200px;
}

.project-view-detail .project-view-detail-menu .project-reg-add .file-list {
    width: 90%;
    height: 150px;
    border-radius: 5px;
    border: 1px solid var(--default-text-color-hint);
    outline: none;
    cursor:default;
    margin: 10px;
    padding: 10px;
    overflow: auto;
}

.project-view-detail .project-view-detail-menu .project-reg-add .file-list-item {
    position: relative;
    width: 300px;
    height: 28px;
    margin: 10px;
    color: var(--color-gray-1);
    border: 1px solid var(--color-gray-1);
    border-radius: 10px;
}

.project-view-detail .project-view-detail-menu .project-reg-add .file-list-item .part-divider {
    margin: 0 5px;
}

.project-view-detail .project-view-detail-menu .project-reg-add .file-list-item .code.x.icon {
    color: var(--default-text-color-hint);
}

.project-view-detail .project-view-detail-menu .project-reg-add .file-list-item .code.x.icon:hover {
    color: #FFF;
    background-color: #000;
}


.project-view-detail .project-view-detail-menu .project-reg-add .button {
    font-weight: 700;
    font-size: 14px;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    margin: 10px 5px 0px 20px;
    width: 90px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
}

.project-view-detail .project-view-detail-menu .project-reg-add .button:hover {
    background-color: var(--color-primary);
    color: #FFF;
}

.project-view-detail .project-view-detail-menu .inspection-mark {
    font-size: 14px;
    width: 85px;
    height: 22px;
    border-radius: 5px;
    color: #FFF;
}

.project-view-detail .project-view-detail-menu .inspection-mark.pass {
    background-color: #47D27F;
}

.project-view-detail .project-view-detail-menu .inspection-mark.fail {
    background-color: #FF3131;
}

.project-view-detail .project-view-detail-menu .client-message {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    border: 1px solid #EEE;
    border-radius: 5px;
    width: 500px;
    height: 100px;
    padding: 10px;
    margin: 20px 10px 10px 10px;
    color: var(--default-text-color);
    outline: none;
}

.project-view-detail .project-view-detail-menu .score .score-group {

}

.project-view-detail .project-view-detail-menu .score .score-group .score-input {
    font-size: 18px;
    font-weight: 700;
    width: 60px;
    height: 50px;
    margin: 0 0 0 30px;
    text-align: center;
    outline: none;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
}

.project-view-detail .project-view-detail-menu .download-button {
    font-size: 14px;
    margin: 5px;
    padding: 5px;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .download-button label {
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .download-button:hover {
    text-decoration: underline;
}



.project-view-detail .project-view-detail-menu .payment-title {
    font-weight: 700;
    font-size: 14px;
    width: 100px;
    height: 25px;
    color: #FFF;
    border-radius: 5px 5px 0 0;
    margin: 0 10px;
}

.project-view-detail .project-view-detail-menu .payment-title.client {
    background-color: var(--color-primary);
}

.project-view-detail .project-view-detail-menu .payment-title.partner {
    background-color: var(--color-secondary);
}


.project-view-detail .project-view-detail-menu .payment-content {
    border-radius: 0 5px 5px 5px;
    width: 380px;
    height: 100%;
    padding: 10px;
    margin: 0 10px;
}

.project-view-detail .project-view-detail-menu .payment-content.client {
    border: 1px solid var(--color-primary);
}

.project-view-detail .project-view-detail-menu .payment-content.partner {
    border: 1px solid var(--color-secondary);
}

.project-view-detail .project-view-detail-menu .total-payment {
    font-weight: 700;
    font-size: 18px;
    text-align: end;
    margin: 5px;
}

.project-view-detail .project-view-detail-menu .payment-step {
    margin: 20px 10px 30px 10px;
}

.project-view-detail .project-view-detail-menu .payment-step .step {
    font-weight: 400;
    font-size: 14px;
    color: var(--default-text-color-hint-1);
}

.project-view-detail .project-view-detail-menu .payment-step .payment {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.project-view-detail .project-view-detail-menu .payment-step .payment .payment-text {
    font-weight: 700;
    font-size: 16px;
    margin: 5px;
}

.project-view-detail .project-view-detail-menu .payment-step .payment-input {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    width: 60px;
    height: 30px;
    outline: none;
    border: 1px solid var(--default-text-color-hint);
    padding: 5px;
}

.project-view-detail .project-view-detail-menu .payment-step .payment-input.detail {
    width: 200px;
    margin: 0 5px 0 0;
    font-weight: 400;
    font-size: 14px;
    text-align: start;
}

.project-view-detail .project-view-detail-menu .payment-step .name {
    font-weight: 400;
    font-size: 14px;
    color: var(--default-text-color-hint-1);
    margin: 0 0 0 5px;
}

.project-view-detail .project-view-detail-menu .detail-button {
    width: 30px;
    height: 30px;
    font-size: 25px;
    font-weight: 700;
    border-radius: 5px;
    background-color: var(--color-tertiary-2);
    color: var(--color-tertiary);
    margin: 0px 2px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .payment-mark {
    font-size: 14px;
    border-radius: 5px;
    height: 20px;
    margin: 0 10px;
    width: 50px;
    height: 25px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .payment-mark.client {
    background-color: var(--color-primary-1);
    border: 1px solid var(--color-primary-1);
    color: #FFF;
}

.project-view-detail .project-view-detail-menu .payment-mark.client:hover {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.project-view-detail .project-view-detail-menu .payment-mark.partner {
    background-color: var(--color-secondary-1);
    border: 1px solid var(--color-secondary-1);
    color: #FFF;
}

.project-view-detail .project-view-detail-menu .payment-mark.partner:hover {
    background-color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
}

.project-view-detail .project-view-detail-menu .payment-mark.none {
    background-color: var(--color-gray-2);
    color: var(--color-gray-1);
}

.project-view-detail .project-view-detail-menu .payment-mark.none:hover {
    background-color: #FFF;
    border: 1px solid var(--color-gray-2);
}


.project-view-detail .project-view-detail-menu .payment-content.client .payment-bill {
    border: 1px solid var(--color-primary-1);
    color: var(--color-primary-1);
}

.project-view-detail .project-view-detail-menu .payment-content.partner .payment-bill {
    border: 1px solid var(--color-secondary);
    color: var(--color-secondary);
}






.project-view-detail .project-view-detail-menu .button-group {
    display: flex;
    justify-content: end;
    margin: 50px 0 0 0;
}

.project-view-detail .project-view-detail-menu .button-group .button {
    font-weight: 700;
    font-size: 14px;
    height: 30px;
    border-radius: 5px;
    margin: 5px;
    padding: 5px 15px;
    color: #FFF;
    cursor: pointer;
}

.project-view-detail .project-view-detail-menu .button-group .button.cancel {
    background-color: var(--color-gray-2);
    border: 1px solid var(--color-gray-2);
    color: var(--color-gray-1);
}

.project-view-detail .project-view-detail-menu .button-group .button.cancel:hover {
    background-color: #FFF;
}

.project-view-detail .project-view-detail-menu .button-group .button.save {
    background-color: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
}

.project-view-detail .project-view-detail-menu .button-group .button.save:hover {
    background-color: #FFF;
    color: var(--color-tertiary);
}





.project-view-detail .project-view-detail-sub {
    min-width: 325px;
    /* height: 650px; */
    margin: 10px;
    box-sizing: border-box;
    background-color: #FFF;
}

.project-view-detail .project-view-detail-sub .sub-header {
    width: 100%;
    /* height: 130px; */
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    padding: 10px;
    margin-bottom: 20px;
}

.project-view-detail .project-view-detail-sub .sub-header .fa-heart {
    font-size: 20px;
}

.project-view-detail .project-view-detail-sub .sub-header .button {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: var(--color-secondary);
    color: #FFF;
    border-radius: 5px;
    width: 284px;
    height: 47px;
    margin: 5px 10px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-sub .sub-header .button:hover {
    background-color: var(--color-secondary-p2);
}

.project-view-detail .project-view-detail-sub .sub-header .button.reg {
    background-color: #FFF;
    color: var(--default-text-color);
    border: 1px solid var(--color-red);
}

.project-view-detail .project-view-detail-sub .sub-header .button.reg:hover {
    border: 3px solid var(--color-red);
}

.project-view-detail .project-view-detail-sub .sub-header .button.reg .fa-heart {
    color: red;
    margin: 0 10px 0 0;
}

.project-view-detail .project-view-detail-sub .sub-header .button.cancel {
    background-color: var(--color-gray-1);
    color: #FFF;
}

.project-view-detail .project-view-detail-sub .sub-header .button.cancel:hover {
    background-color: var(--color-gray);
}

.project-view-detail .project-view-detail-sub .sub-header .button.save {
    background-color: #FFF;
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
}

.project-view-detail .project-view-detail-sub .sub-header .button.save:hover {
    background-color: var(--color-secondary);
    color: #FFF;
}

.project-view-detail .project-view-detail-sub .sub-header .button.complete {
    background-color: var(--color-secondary);
    color: #FFF;
}

.project-view-detail .project-view-detail-sub .sub-header .button.complete:hover {
    background-color: #FFF;
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
}

.project-view-detail .project-view-detail-sub .sub-header .button.none {
    background-color: var(--color-gray-2);
    color: #FFF;
    cursor: default;
}

.project-view-detail .project-view-detail-sub .sub-main {
    width: 100%;
    height: 420px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    /* margin: 20px 0 0 0; */
    padding: 20px;
}

.project-view-detail .project-view-detail-sub .sub-main .client-logo {
    background-image: url('../../../public/images/User-default-Image.jpg');
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    border: 2px solid #EEE;
    border-radius: 15px;
}

.project-view-detail .project-view-detail-sub .sub-main .client-name {
    margin: 0 30px 0 10px;
}

.project-view-detail .project-view-detail-sub .sub-main .client-mark {
    font-weight: 600;
    font-size: 12px;
    background-color: #FC8585;
    color: #FFF;
    border-radius: 5px;
    height: 20px;
    padding: 0 10px;
}

.project-view-detail .project-view-detail-sub .sub-main .client-score {
    margin: 15px 30px 15px 10px;
    color: var(--default-text-color-hint);
    letter-spacing: 1px;
}

.project-view-detail .project-view-detail-sub .sub-main .client-etc {
    width: 280px;
    height: 32px;
    margin: 10px 0;
    color: var(--default-text-color-hint);
    letter-spacing: 1px;
    white-space: normal;
}

.project-view-detail .project-view-detail-sub .sub-main .client-text {
    font-weight: 400;
    color: var(--default-text-color-hint-1);
    margin: 10px 0;
}

.project-view-detail .project-view-detail-sub .sub-main .view-button {
    font-weight: 600;
    font-size: 14px;
    background-color: var(--color-primary);
    color: #FFF;
    border-radius: 5px;
    width: 250px;
    height: 45px;
    margin: 20px 20px 0 20px;
    cursor: pointer;
}

.project-view-detail .project-view-detail-sub .sub-main .view-button:hover {
    background-color: var(--color-primary-p2);
}

.project-view-detail .sub-project {
    width: 400px;
    /* height: 570px; */
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    margin: 10px;
    padding: 20px;
}

.project-view-detail .sub-project .part-connecter {
    width: 5px;
    height: 25px;
    background-color: var(--default-text-color-hint);
    opacity: 0.2;
    margin-left: 75px;
}

.project-view-detail .sub-project .project-step-com {
    font-size: 16px;
    font-weight: 500;
    background-color: var(--color-primary);
    color: #FFF;
    width: 150px;
    height: 35px;
    border-radius: 5px;
}

.project-view-detail .sub-project .project-step-pro {
    font-size: 16px;
    font-weight: 700;
    background-color: #FFF;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    width: 150px;
    height: 35px;
    border-radius: 5px;
}

.project-view-detail .sub-project .project-step-pre {
    font-size: 16px;
    font-weight: 500;
    background-color: var(--default-text-color-hint);
    color: #FFF;
    width: 150px;
    height: 35px;
    border-radius: 5px;
}

.project-view-detail .sub-project .project-step-pro-text {
    font-weight: 700;
    margin: 0 20px;
    color: var(--color-primary);
}

.project-view-detail .sub-project .project-step-pre-text {
    margin: 0 20px;
    color: var(--default-text-color-hint);
}

.project-view-detail .sub-project .result-product {
    width: 100%;
    height: 45px;
    background-color: var(--color-gray-3);
    font-weight: 700;
    font-size: 16px;
    color: var(--color-gray-2);
    border-radius: 5px;
    margin: 40px 0 0 0;
    cursor: pointer;
    letter-spacing: 1px;
}

.project-view-detail .sub-project-manager {
    width: 400px;
    height: 250px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    margin: 20px 10px;
    padding: 20px;
}

.project-view-detail .sub-project-manager.info {
    height: 680px;
}

.project-view-detail .sub-project-manager .button {
    width: 180px;
    height: 35px;
    background-color: var(--color-tertiary);
    color: #FFF;
    border-radius: 5px;
    margin: 30px 90px 0 90px;
    cursor: pointer;
}

.project-view-detail .sub-project-manager .button.small {
    width: 120px;
    height: 30px;
    margin: 0;
}

.project-view-detail .sub-project-manager .button:hover {
    background-color: #FFF;
    color: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
}

.project-view-detail .sub-project-manager .ui.selection.dropdown {
    border: 1px solid var(--default-text-color);
    padding: 0 0 0 10px;
    width: 250px;
    height: 30px;
}

.project-view-detail .sub-project-manager .ui.selection.dropdown.template .divider.text {
    text-align: center;
    width: 180px;
}

.project-view-detail .sub-project-manager .content-text {
    height: 40px;
}

.project-view-detail .sub-project-manager .checkbox {
    margin: 15px;
}

.project-view-detail .sub-project-manager .process-date {
    margin: 10px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    height: 30px;
    text-align: center;
}

.project-view-detail .sub-project-manager .contract-checkbox {
    margin: 10px 0 20px 0;
}

.project-view-detail .sub-project-manager .contract-id {
    border-radius: 5px;
    border: 1px solid var(--default-text-color-hint);
    outline: none;
    padding: 10px;
    height: 30px;
    width: 250px;
}

.project-view-detail .sub-project-manager .part-divider {
    margin: 15px 0;
}

.project-view-detail .sub-project-manager .header {
    font-weight: 700;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 5px 0 10px 0;
}

.project-view-detail .sub-project-manager .title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: var(--color-gray-1);
    margin: 5px;
    width: 100px;
    height: 22px;
}

.project-view-detail .sub-project-manager .content {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: var(--default-text-color);
    margin: 5px;
    word-break:break-all;
    height: 22px;
}

.project-view-detail .project-view-detail-sub .part-divider {
    width: 90%;
}

/* 프로젝트 단계별 textarea */
/* realative - absolute */
/* .project-view-detail .project-view-detail-menu .menu-content .text-content {
    position: relative;
}

.project-view-detail .project-view-detail-menu .menu-content .text-content .progress-btn-box {
    position: absolute;
    top: 10px;
    right: 10px;
} */

.project-view-detail .project-view-detail-menu .menu-content .text-content .progress-btn-box {
    height: 30px;
    margin: 15px 0 0 15px;
    /* margin-left: 15px; */
}

.project-view-detail .project-view-detail-menu .menu-content .text-content .progress-btn-box .progress-btn {
    display: flex;
    transition: background-color 0.3s ease-out;
}

.project-view-detail .project-view-detail-menu .menu-content .text-content .progress-btn-box .progress-btn.cancel {
    background-color: var(--color-gray-2);
    /* background-color: var(--gray-2, #D0D0D0); */
}

.project-view-detail .project-view-detail-menu .menu-content .text-content .progress-btn-box .progress-btn.cancel:hover {
    background-color: var(--color-gray);
    /* background-color: var(--gray-2, #D0D0D0); */
}

.project-view-detail .project-view-detail-menu .menu-content .text-content .progress-btn-box .progress-btn.save {
    background-color: var(--color-tertiary);
    margin-left: 10px;
}

.project-view-detail .project-view-detail-menu .menu-content .text-content .progress-btn-box .progress-btn.save:hover {
    background-color: var(--color-tertiary-p1);
}