/* .bulletinBoardList-container .bulletinBoardList-list .bulletinBoardList-table .table-label {
    padding: 10px 0;
    border-bottom: 1px solid #464646;
} */
.Manager-page-container > * {
    font-family: 'Noto Sans KR';
    color: var(--default-text-color);
    /* font-weight: 700; */
}

.Manager-page-container .manager .manager-main .account-mgt-table {
    /* font-family: 'Noto Sans KR'; */
    font-size: 12px;
    font-weight: 400;
    line-height: 17.38px;
    text-align: left;
    color: #000000;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label {
    padding: 10px 0;
    border-bottom: 0.5px solid #B7B7B7;
    /* border-bottom: 1px solid #464646; */
    /* font-family: 'Noto Sans KR';
    font-size: 12px;
    font-weight: 400;
    line-height: 17.38px;
    text-align: left; */
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label.column-nm {
    margin-top: 20px;
    border-top: 0.5px solid #B7B7B7;
    
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell {
    /* font-family: 'Noto Sans KR'; */

    /* font-weight: 500;
    font-size: 14px;
    color: #464646;
    text-align: center; */

    /* vertical-align: middle;
    letter-spacing: 1px; */

    flex: 1;
    /* cursor: default; */

    /* height: 35px; */
    /* border-bottom: 1px solid #464646; */
    white-space:nowrap;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell.num {
    flex: 0.5;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell.email {
    flex: 2.5;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell.wide {
    flex: 1.2;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell.email.data {
    justify-content: flex-start;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell.email.data:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell .send-msg {
    color: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
    transition: background-color 0.3s ease-out;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell .send-msg:hover {
    background-color: var(--color-tertiary);
    color:#FFFFFFFF;
    cursor: pointer;
}

/* 페이지네이션 */
.Manager-page-container .manager .manager-main .account-mgt-table .pagination {
    margin-top: 15px;
}

/* ReceivedMsg */
.Manager-page-container .manager .manager-main .account-mgt-table {
    font-size: 14px;
    /* line-height: 23.17px;
    text-align: center; */
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .table-cell.reveive {
    font-weight: 600;
    /* line-height: 23.17px;
    text-align: center; */
}

.Manager-page-container .manager .manager-main .account-mgt-table .msg-contents {
    /* justify-content: center; */
    /* align-items: center; */
    padding: 10px 150px;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .msg-icon {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .not-read {
    background-image: url('../../../public/icons/not-read.svg');
}

.Manager-page-container .manager .manager-main .account-mgt-table .table-label .read {
    background-image: url('../../../public/icons/read.svg');

}

.Manager-page-container .manager .manager-main .account-mgt-table .fa-angles-up {
    color: var(--color-gray-2);
    font-size: 18px;
    transition: background-color 0.3s ease-out;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #B7B7B7;
}

.Manager-page-container .manager .manager-main .account-mgt-table .fa-angles-up:hover {
    color: var(--color-gray-1);
}

.Manager-page-container .manager .manager-main .account-mgt-table .fa-angles-up:hover {
    color: var(--color-gray-1);
}

/* 받은 메시지 데이터 없을때 */
.Manager-page-container .manager .manager-main .result-info {
    margin-top: 30px;
    margin-bottom: 0;
}