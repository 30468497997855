form {
    height: auto;
}
.popup {
    z-index: 100;
    position: fixed;
    top: 22vh;
    left: 10%;
    width: 80%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;

    font-family: 'Noto Sans KR';
  }
  .popup__header.undefined {
    /* padding: 10px;
    display: flex; */
  }
  .popup__header {
    width: 100%;
    background: #E0E1E2;
    color: var(--default-text-color);
    border-radius: 8px 8px 0 0;
  }
  .popup__header .popup_header {
    /* font-family: 'Noto Sans KR'; */
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1px;
    color: var(--default-text-color);
    align-items: center;
  }
  .popup__header .code.x.icon{
    margin-top: 2px;
    margin-right: 10px;
  }
  
  .popup__footer {
    padding: 1rem 0.5rem;
    text-align: center;
   
  }

  .popup__content {
    /* height: 65%; */
    /* padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center; */
    height: 100%;
    min-height: 190px;
    position: relative;
    white-space: pre-wrap;
  }

  .popup.changepw {
    /* width: 850px; */
    /* height: 610px; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
  }
  
  @media (min-width: 760px) {
    .popup {
      left: calc(50% - 175px);
      top: 40%;
      width: 360px;
      height: 190px;
    }
  }
  
  .popup-enter {
    transform: translateY(-10rem);
    opacity: 0;
  }
  
  .popup-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .popup-exit {
    transform: translateY(0);
    opacity: 1;
  }
  
  .popup-exit-active {
    transform: translateY(-10rem);
    opacity: 0;
    transition: all 200ms;
  }
  
  .close.icon {
    float: right;
  }

  .popup__content {
    padding: 20px;
  }

  /* 추가 */
  .popup__content .popup-info {
    margin-top: 15px;
    text-align: center;
  }

  .popup__content .popup-info p {
    font-weight: 700;
    /* font-size: 16px;
    margin: 20px 10px; */
    letter-spacing: 0.5px;
    line-height: 25px;
  }

  .popup__content .popup-info p p { 
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 25px;
  }

  .popup__content .popup-info .ok_btn {
    background: var(--color-primary);
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    padding: 8px 20px;
    margin: 20px 10px;
  }

  .popup__content .popup-info .ok_btn:hover {
    background: var(--color-primary-p2);
  }

  .popup__content .popup-info .close_btn {
      background: var(--color-gray);
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
      padding: 8px 20px;
      margin: 20px 0;
      transition: background-color 0.3s ease-out;

  }

  .popup__content .popup-info .close_btn:hover {
    background: var(--color-gray-p2);
  }

  .popup__content .popup-info .close_btn.ok {
    background: var(--color-gray-1);
    /* margin-bottom: 15px; */
    /* margin: 20px 10px; */
  }

  .popup__content .popup-info .close_btn:hover {
    /* background: var(--color-gray-p2); */
  }

  .popup__content .popup-info .close_btn.yes {
    background-color: var(--color-primary);
    margin-left: 10px;
  }

  .popup__content .popup-info .close_btn.yes:hover {
    background: var(--color-primary-p2);
  }

  .popup__content .popup-info .close_btn.no {
    background-color: var(--color-gray-1);
  }

  .popup__content .popup-info .close_btn.no:hover {
    background-color: var(--color-gray-p1);
  }