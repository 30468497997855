.dropia-reg .rating-box {
    /* justify-content: flex-end; */
    /* margin-bottom: 15px; */
    /* margin-bottom: 20px; */
}

/*  */
.dropia-reg .test {
    /* flex-grow: 2; */
}

.dropia-reg .rating-box .main-text {
    margin-bottom: 0;
}

.dropia-reg .rating-container:first-child {
    flex: 2;
}

.dropia-reg .rating-container.overall-rating-box {
    flex: 1;
    justify-content: flex-end;
    margin-right: 15px;
}

.dropia-reg .overall-rating-box .overall-rating-factors {
    /* min-height: 30px; */
    /* justify-content: center; */
    margin: 0 8px;
    /* width: 100%; */
}


.dropia-reg .overall-rating-box span {
    font-size: 12px;
    font-weight: 400;
    flex-basis: auto;
    margin: 5px 0;
}


.dropia-reg .overall-rating-box .bar-chart {
    /* background-color: #D9D9D9; */
    /* background-color: #FBB01F; */
    width: 8px;
    height: 60px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 내가 받은 리뷰 */
.dropia-reg .portfolio-menubar .main-text {
    width: auto;
    /* margin: 20px 0px; */
}
.dropia-reg .portfolio-menubar .view-all {
    font-size: 14px;
    font-weight: 400;
    /* line-height: 20.27px; */
}

.dropia-reg .progress-box.req .rating-box p:nth-child(n+2) {
    margin-right: auto;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    width: auto;
    /* line-height: 17px; */
    /* letter-spacing: 0em; */
}

.dropia-reg .project-progress .progress-box.req.review {
    flex: 1;
}

.dropia-reg .project-progress .progress-box.req.review:nth-child(2) {
    flex: 1;
}

.dropia-reg .project-progress .progress-box.req.review .exclamation-mark {
    width: 25px;
    height: 25px;
    background-image: url('../../../public/icons//exclamation-mark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    /* margin: 20px; */
}

.dropia-reg .project-progress .progress-box.req.review label {
    margin: 5px 0 0 0;
}

.dropia-reg .project-progress.req .hr-solid {
    margin: 0 0 10px 0;
    width: 95%;
}

/* 비율 */
.dropia-reg .rating-container.detail:first-child {
    flex: 1;
    height: 100%;
    /* margin-left: 0; */
}

.dropia-reg .rating-container.detail .rating-factors-box.add {
    /* 리뷰 6개항목 */
    flex-wrap: wrap; 
}

.dropia-reg .rating-container.detail.overall-rating-box {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    align-items: flex-start;
}

.dropia-reg .rating-container.detail.overall-rating-box::first-line {
    line-height: normal;
}

.dropia-reg .rating-container.detail .rating-factors-box .rating-factors {
    margin-bottom: 6px;
    min-width: 193px;
}

.dropia-reg .rating-container.detail .rating-factors-box:last-child {
    /* margin-bottom: 15px; */
}

/* 리뷰 입력 */

.dropia-reg .dropia-reg-main .review-input-box {
    margin-bottom: 15px;
}

.dropia-reg .dropia-reg-main .rating-input {
    /* overflow: hidden; */
    border: 1px solid var(--color-red) !important;
    /* padding-block: 2px; */
    /* padding-inline: 3px; */
    width: 35px;
    margin-left: 10px;
    padding: 4px 2px;
}

.dropia-reg .dropia-reg-main .rating-input.review-text {
    padding: 4px 40px 4px 8px;
}

.dropia-reg .dropia-reg-main .overall-rating-box.text {
    position: relative;
    margin: 0 10px 6px 20px;
    justify-content: flex-start;
}

.dropia-reg .dropia-reg-main .overall-rating-box.text .review-add {
    position: absolute;
    bottom: 6px;
    right: 6px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.dropia-reg .dropia-reg-main .overall-rating-box.text .review-add:hover {
    /* text-decoration: underline; */
    outline: 1px solid var(--default-text-color);
    border-radius: 10px; /* 둥근 모서리의 반지름 값 설정 */
}

/* 평점 */
/* .dropia-reg .rating-box .star-rating-box .fa-star {
    color: #FBB01F;
} */

/* input[type="text" i] {
    padding-block: 1px;
    padding-inline: 2px;
} */

/* .dropia-reg .overall-rating-box .bar-chart {
    background-color: #D9D9D9;
    width: 8px;
    height: 130px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */