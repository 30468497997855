body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url('../public/fonts/NotoSansKR-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'KoPubDotum Bold';
  src: url('../public/fonts/KoPubDotumBold.ttf') format('truetype');
}

@font-face {
  font-family: 'KoPubDotum Medium';
  src: url('../public/fonts/KoPubDotumMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'KoPubDotum Light';
  src: url('../public/fonts/KoPubDotumLight.ttf') format('truetype');
}