.Partner-container {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    justify-content: center;
    background-color: var(--color-gray-3);
    height: 100%;
}

.partner-view {
    display: flex;
    justify-content: center;
}

.partner-view .partner-view-list {
    height: 100%;
    margin: 10px;
}

.partner-view .partner-view-list .list-header {
    width: 100%;
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;
}

.partner-view .partner-view-list .list-header .header-text {
    height: 55px;
    display: flex;
    align-items: center;
}

.partner-view .partner-view-list .list-header .header-text .main-text {
    font-weight: 600;
    font-size: 18px;
}

.partner-view .partner-view-list .list-header .header-text .sub-text {
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 0 10px;
}

.partner-view .partner-view-list .list-header .sort-button {
    background-color: #FFF;
    padding: 10px;
    height: 35px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    cursor: pointer;
}

.partner-view .partner-view-list .list-header .sort-button:hover {
    border: 2px solid var(--default-text-color-hint);
}

.partner-view .partner-view-list .list-header .sort-button .sort-image {
    background-image: url("../../../public/images/arrow-down-wide-short-solid.svg");
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 15px;
}

.partner-view .partner-view-list .list-header .sort-button .sort-text {
    font-weight: 600;
    font-size: 14px;
}

.partner-view .partner-view-list .list-header .sort-button .sort-text.active {
    margin: 0 5px;
    padding: 5px;
}


.partner-view .partner-view-list .list-header .sort-button .sort-text.active:hover {
    background-color: var(--color-primary-1);
    color: #FFF;
    border-radius: 10px;
}

.partner-view .partner-view-list .list-main {
    width: 860px;
}

.partner-view .partner-view-list .partner-summary-box {
    background-color: #FFF;
    width: 860px;
    height: 180px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    cursor: default;

    opacity: 0;
    -webkit-animation: fadein-moveleft 1.0s;
    animation-fill-mode: forwards;
}

.partner-view .partner-view-list .partner-summary-box:hover {
    border: 1.5px solid var(--color-primary);
}

.partner-view .partner-view-list .partner-summary-box .partner-logo {
    width: 100px;
    height: 100px;
    margin: 30px 0;
    border: 2px solid #EEE;
    border-radius: 50px;
}

.partner-view .partner-view-list .partner-summary-box .partner-logo.null {
    background-image: url("../../../public/images/User-default-Image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.partner-view .partner-view-list .partner-summary-box .part-main {
    padding: 20px 30px;
}

.partner-view .partner-view-list .partner-summary-box .part-main .partner-name {
    font-weight: 700;
    font-size: 20px;
    color: var(--color-primary);
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.partner-view .partner-view-list .partner-summary-box .part-main .partner-type {
    font-weight: 500;
    font-size: 15px;
    color: var(--default-text-color-hint);
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.partner-view .partner-view-list .partner-summary-box .part-main .partner-certification {
    font-weight: 500;
    font-size: 15px;
    color: var(--default-text-color-hint);
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.partner-view .partner-view-list .partner-summary-box .part-main .fa-building {
    color: var(--default-text-color);
}

.partner-view .partner-view-list .partner-summary-box .part-main .fa-award {
    color: #F95632;
}

.partner-view .partner-view-list .partner-summary-box .part-main .fa-heart {
    color: #FF3131;
    margin: 0 10px;
    cursor: pointer;
}

.partner-view .partner-view-list .partner-summary-box .part-main .partner-detail {
    font-weight: 700;
    font-size: 14px;
    color: var(--color-secondary-p1);
    margin: 10px 0;
    border: 2px solid var(--color-secondary-p1);
    border-radius: 5px;
    width: 70px;
    height: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
}

.partner-view .partner-view-list .partner-summary-box .part-main .partner-detail:hover {
    background-color: var(--color-secondary-p1);
    color: #FFF;
}

.partner-view .partner-view-list .partner-summary-box .part-main .partner-introduce {
    font-weight: 400;
    font-size: 14px;
    width: 450px;
    line-height: 20px;
    color: var(--default-text-color);
}


.partner-view .partner-view-list .partner-summary-box .part-sub {
    display: flex;
    justify-content: center;
    width: 200px;
}

.partner-view .partner-view-list .partner-summary-box .part-sub .score {
    margin: 10px 0;
}

.partner-view .partner-view-list .partner-summary-box .part-sub .score .text {
    font-weight: 600;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 0 0 35px;
}

.partner-view .partner-view-list .partner-summary-box .partner-option.textA {
    font-weight: 500;
    font-size: 14px;
    color: var(--default-text-color-hint);
    width: 120px;
    display: flex;
    align-items: center;
    margin: 10px 10px 10px 0;
}

.partner-view .partner-view-list .partner-summary-box .partner-option.textB {
    font-weight: 600;
    font-size: 14px;
    color: var(--default-text-color);
    margin: 0 5px;
    width: 60px;
    display: flex;
    align-items: center;
    
}

.partner-view .partner-view-list .partner-summary-box .partner-option.textC {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-secondary-p1);
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
}

.partner-view .partner-view-list .pagination{
    width: 500px !important;
    height: 35px;
    margin: 20px 0 !important;
    /* position: absolute; */
    /* bottom: 5%; */
}




.partner-view .partner-view-filter {
    min-width: 325px;
    /* height: 650px; */
    margin: 10px;
    border-radius: 10px;
    box-sizing: border-box;
}

.partner-view .partner-view-filter .filtering-option {
    background-color: #FFF;
    width: 100%;
    /* height: 700px; */
    margin: 75px 0 0 0;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #DADCE0;
}

.partner-view .partner-view-filter .filtering-option .filtering-header {
    width: 100%;
    height: 40px;
    background-color: var(--color-secondary-p1);
    border-radius: 5px 5px 0 0;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.partner-view .partner-view-filter .filtering-option .filtering-header .header-text {
    color: #FFF;
}


.partner-view .partner-view-filter .filtering-option .filtering-main .header-text {
    margin: 10px 0;
}

.partner-view .partner-view-filter .filtering-option .filtering-main .filtering-type {
    padding: 10px 20px;
}

.partner-view .partner-view-filter .filtering-option .filtering-form {
    padding: 10px 20px;
}

.partner-view .partner-view-filter .filtering-option .filtering-score {
    padding: 10px 20px;
}

.partner-view .partner-view-filter .filtering-option .ui.selection.dropdown {
    width: 150px;
    height: 30px;
    border: 1px solid var(--default-text-color-hint);
}

.partner-view .partner-view-filter .filtering-option .input {
    width: 100px;
    margin: 5px 10px 5px 0;
    height: 30px;
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    outline: none;
}

.partner-view .partner-view-filter .filtering-option .filtering-location {
    padding: 10px 20px;
}

.partner-view .partner-view-filter .filtering-option .filtering-location .sido-group {
    display: grid;
    width: 325px;
    height: 225px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.partner-view .partner-view-filter .filtering-option .filtering-factor {
    margin: 10px 0;
}

.partner-view .partner-view-filter .filtering-option .filtering-factor .checkbox {
    margin: 0 10px 0 0;
    width: 15px;
}

.partner-view .partner-view-filter .filtering-option .textB {
    font-weight: 500;
    font-size: 16px;
    color: var(--default-text-color);
}
