.Home-container {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Main-contents-top {
    width: 960px;
    height: 514px;
    margin: 50px 0;
    position: relative;
}

.Main-contents-top .textA {
    position: absolute;
    top: 15%;
    font-weight: 700;
    font-size: 28px;
    color: var(--color-green);
    opacity: 0;
    -webkit-animation: fadein 0.5s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}

.Main-contents-top .text-group {
    position: absolute;
    top: 23%;
    display: flex;
    opacity: 0;
    -webkit-animation: fadein 0.5s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
}

.Main-contents-top .text-group .logo {
    background-image: url('../../../public/images/dropia_logo.svg');
    background-repeat:no-repeat;
    background-position: left;
    width: 130px;
    height: 35px;
    background-size: 80%;
    margin: 0 -15px 0 0;
}

.Main-contents-top .text-group .textB {
    font-weight: 700;
    font-size: 28px;
    color: #000000
}

.Main-contents-top .button {
    width: 284px;
    height: 47px;
    font-weight: 600;
    font-size: 18px;
    background-color: var(--color-primary);
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 35%;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease-out;
    
    /*animation*/
    opacity: 0;
    -webkit-animation: fadein 1s;
    animation-delay: 0.9s;
    animation-fill-mode: forwards;
}

.Main-contents-top .button:hover {
    background-color: var(--color-primary-p2);
}

.Main-contents-top .image {
    width: 100%;
    height: 100%;
    background-image: url('../../../public/images/Main1.png');
    background-repeat:no-repeat;
    background-position: center;
    /* -webkit-animation: fadein 2s; */
}

.Help-Text {
    font-weight: 700;
    font-size: 20px;
    margin: 20px 0;
    width: 960px;
    text-align: start;

    opacity: 0;
    -webkit-animation: fadein 0.5s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
}

.Main-contents-bottom {
    display: flex;
    flex-direction: row;
}

.Main-contents-bottom .group-left{
    width: 452px;
    height: 253px;
    background-color: #EDF3FF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: start;
    margin: 0 20px;
    box-sizing: border-box;
    padding: 20px 30px;
    position: relative;

    opacity: 0;
    -webkit-animation: fadein-moveup 1.0s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
}

.Main-contents-bottom .group-right{
    width: 452px;
    height: 253px;
    background-color: #E4FFEF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: start;
    margin: 0 20px;
    box-sizing: border-box;
    padding: 20px 30px;
    position: relative;

    opacity: 0;
    -webkit-animation: fadein-moveup 1.0s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
}

.Main-contents-bottom .text-group {
    display: flex;
}

.Main-contents-bottom .text-logo {
    font-size: 24px;
    font-weight: 650;
    color: var(--color-primary);
    margin: 0 8px 0 0;
}

.Main-contents-bottom .text-title {
    font-size: 24px;
    font-weight: 600;
}

.Main-contents-bottom .text-group {
    width: 320px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    margin-top: 15px;
    letter-spacing: 1px;
}

.Main-contents-bottom .text-exp {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
    color: var(--default-text-color);
}

.Main-contents-bottom .text-detail {
    font-size: 16px;
    font-weight: 600;
    color: #2F71AE;
    margin-top: 50px;
    cursor: pointer;
}

.Main-contents-bottom .text-detail:hover{
    text-decoration: underline;
}

.Main-contents-bottom .group-left .image{
    width: 123px;
    height: 123px;
    background-image: url('../../../public/images/main-bottom1.png');
    background-repeat:no-repeat;
    background-position: center;
    position: relative;
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.Main-contents-bottom .group-right .image{
    width: 175px;
    height: 118px;
    background-image: url('../../../public/images/main-bottom2.png');
    background-repeat:no-repeat;
    background-position: center;
    position: absolute;
    bottom: 5%;
    right: 5%;
}