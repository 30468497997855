.changepw-container > * {
    font-family: 'Noto Sans KR';
    /* font-weight: 700; */
    /* color: var(--default-text-color); */
}

.changepw-container {
    position: absolute;
    top: calc(50% - (500px / 2));
    left: calc(50% - (550px / 2));
    /* background-color: #E0E1E2; */
    background-color: #F6F6F6;
    border: 2px solid rgba(41, 135, 205, 0.6);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    border-radius: 48px;
    width: 550px;
    /* width: 620px; */
    height: 400px;
    /* align-items: center;
    justify-content: center; */
    
}

.changepw-container .title {
    font-weight: 700;
    font-size: 26px;
    /* color: var(--default-text-color); */
    margin: 30px 0;
}

.changepw-container .changepw-inputuser {
    /* align-items: center; */
    /* justify-content: center; */
    /* width: 100%; */
    /* display: flex; */
    /* flex-direction: column; */
    padding: 0 70px;
    /* width: 100%; */
    /* justify-content: center; */
    /* align-items: center; */
}

.changepw-container .changepw-inputuser .field {
    /* display: flex;
    flex-direction: row;
    align-items: center; */
    margin: 12.5px 0;

    /* display: flex; */
    /* justify-content: space-evenly; */
    /* align-items: center; */
    
    /* display: inline-block; */
    /* font-family: Noto Sans KR; */
    position: relative;
    width: 100%;
}

/* .changepw-container .changepw-inputuser .field .text {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--default-text-color);
} */

.changepw-container .changepw-inputuser .field .label {
    /* font-family: 'Noto Sans KR'; */
    font-weight: 600;
    /* font-size: 16px; */
    /* color: var(--theme-color); */
    /* display: flex; */
    justify-content: right;
    /* align-items: center; */
    flex-basis: 95px;
    flex-shrink: 0;
    margin-right: 15px;
    /* flex-grow: 1; */
}

.changepw-container .changepw-inputuser .field .input-chagepw {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    font-size: 14px;
    color: var(--default-text-color);
    width: 100%;
    padding: 4px 8px;
    /* padding: 5.955px 8px; */
    border: 1px solid var(--color-gray-2);
    border-radius: 5px;
    height: 30px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    text-align: center;
    /* margin-right: 40px; */
}

/* .ui.checkbox label */
.changepw-container .changepw-btn {
    border: none;
    padding: 8px 25px;
    margin-top: 30px;
    /* width: 100px; */
    background-color: var(--color-gray-1);
    transition: background-color 0.3s ease-out;

    /* min-width: 77px;
    height: 30px;
    padding: 8px 25px;
    transition: background-color 0.3s ease-out;  */
}

.changepw-container .changepw-btn:hover {
    background-color: var(--color-gray-p1);
}
.changepw-container .changepw-back {
    position: absolute;
    /* display: flex; */
    /* text-align: right; */
    /* justify-content: right; */
    /* align-items: right; */
    bottom: 30px;
    left: 40px;
    /* text-decoration-line: underline; */
}
.changepw-container .changepw-back:hover {
    text-decoration: underline;
    cursor: pointer;
    /* position: relative;
    border-bottom: 1px solid;
    padding-bottom: 3px; */
}

.changepw-container .changepw-inputuser .password{
    font-size: 14px;
    color: var(--);
}
.changepw-container .changepw-inputuser .password.matching {
    color: var(--color-primary);
}
.changepw-container .changepw-inputuser .password.unmatching {
    color: red
}

/* 비밀번호 보이기 / 숨기기 */
.changepw-container .changepw-inputuser .showPwIcon {
    height: 20px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-35%);
    width: 20px;
    color: var(--color-gray-1);
}

/* 비밀번호 일치여부 문구 */
.changepw-container .changepw-inputuser .sign-up-center {
    font-size: 14px;
}