/* .Manager-page-container {
    font-family: 'Noto Sans KR' !important;
    display: flex;
    justify-content: center;
    background-color: var(--color-gray-3);
    height: 100%;
} */

/* Dropia 복붙 */
.Manager-page-container > * {
    font-family: 'Noto Sans KR';
    color: var(--default-text-color);
    font-weight: 700;
}

.Manager-page-container {
    width: 100%;
    height: 100%;
    background-color: #EEEEEE;
}

.manager {
    display: flex;
    justify-content: center;
}

/* .manager > * {
    font-family: 'Noto Sans KR';
    color: var(--default-text-color);
    font-weight: 700;
} */

.manager .manager-main {
    /* display: flex;
    flex-direction: column; */
    /* width: 860px; */
    width: 960px;
    height: 100%;
    /* max-height: 800px; */
    /* overflow-y: auto; */
    background-color: #FFFFFF;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    box-sizing: border-box;
    /* padding: 30px 40px; */
    padding: 30px;
    
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* width: 100%; */
}

.manager .manager-main .main-title {
    font-family: 'Noto Sans KR';
    font-size: 18px;
    font-weight: 700;
    margin-right: 15px;
    /* line-height: 26px; */
    /* letter-spacing: 0em; */
}

.manager .manager-main .main-title .dropia {
    color: #487FF9;
}

.manager .manager-main .tag-flex {
    /* display: flex; */
    /* margin-right: 10px; */
    /* margin-left: 10px; */
}

.manager .manager-main .tag-flex:last-child {
    /* margin-right: 0; */
}

/* 파트너 */
.manager .partner:hover {
    background-color: var(--color-secondary-1);
    cursor: default;
}

/* 클라이언트 */
.manager .client:hover  {
    background-color: var(--color-primary);
    cursor: default;
}

.manager .manager-main .hr-solid {
    border: 1px solid #D0D0D0;
    width: 100%;
    margin: 20px 0;
    /* margin-top: 20px; */
}

.manager .main-text {
    width: 100%;
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
    /* margin: 20px 0px; */
}

/* 진행중인 프로젝트 */
.manager .project-progress {   
    justify-content: space-between;
    /* margin-bottom: 10px; */
    /* box-sizing: border-box; */
}

.manager .project-progress .progress-circle {
    width: 106px;
    height: 106px;
    background-image: url('../../../public/icons/progress_circle.svg');
    background-repeat:no-repeat;
    background-position: center;
    /* color: #FFFFFF; */
}

.manager .project-progress .progress-circle:hover {
    background-image: url('../../../public/icons/progress-circle-click.svg');
    cursor: pointer;
    color: #FFFFFF;
}

.manager .project-progress .progress-circle span:hover {
    color: #FFFFFF;
}

.manager .project-progress .progress-circle.click {
    background-image: url('../../../public/icons/progress-circle-click.svg');
}

.manager .project-progress .progress-circle.click span {
    color: #FFFFFF;
}


.manager .project-progress .fa-chevron-right {
    color: #D0D0D0;
}

.manager .project-progress .progress-circle > span:nth-child(1) {
    margin-bottom: 8px;
}

.manager .project-progress .progress-circle > span:nth-child(2) {
    color: #000000;
}

.manager .project-progress .progress-circle:hover > span:nth-child(2) {
    color: #FFFFFF;
}

.manager .project-progress .progress-circle.click > span:nth-child(2) {
    color: #FFFFFF;
}

.manager .progress-box {
    /* height: 100%; */
    background-color: #F6F6F6;
    border-radius: 2px;
    margin: 10px;
    padding: 10px 14px;
    /* min-height: 66px; */
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; 상세보기 */
}

/* 양쪽 다 */
.progress-box p:first-child {
    margin-right: auto; 
}

/* Manager.css 추가 */
.manager .manager-main .progress-box .req-state p:first-child {
    /* margin: 0; */
}

/* .manager .manager-main .progress-box .req-state .project-user-info {
    white-space:nowrap;
    margin: 0;
} */

/* 서브메뉴 */
.manager-sub .progress-box p:first-child {
    margin-bottom: 10px;
}

.project-progress.req .progress-box p:first-child {
    margin-bottom: 10px;
}

.manager .prj-result {
    margin-left: auto; 
    /* margin-top: 15px; */
    font-size: 24px;
    color: #3F6BCC;
}

/* 견적 제안 요청 프로젝트 */
.manager .project-progress.req {
    margin-bottom: 10px;
    /* height: 72px;    */
    background-color: #F6F6F6;
    /* width: 100%; */
    
    /* justify-content: space-between; */
    /* border-radius: 2px; */
    
    /* margin-bottom: 10px; */
    /* box-sizing: border-box; */
}

.manager .project-progress .progress-box.req {
    /* height: 100%; */

    min-height: 72px;
    max-height: 110px;
    
    /* background-color:transparent; */
    margin: 0px;
    /* align-items: flex-start; */
    flex: 2;

    /* padding: 10px 14px; */
    /* min-height: 72px; */
    /* box-sizing: border-box; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
}

.manager .project-progress .progress-box.req:nth-child(2) {
    flex: 1.4;
    /* align-items: flex-start; */
}

.manager .project-progress .progress-box .fa-envelope-open-text {
    margin-left: 5px;
    color: #EF5DA8;
}

.manager .project-progress .progress-box .fa-heart {
    margin-left: 5px;
    color: #FF3131;
}

.manager .project-progress .progress-box .req-state {
    /* min-height: 66px; */
    /* height: 100%; */
    /* background-color: red; */
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    margin: 0 15px;
    /* min-width: 96px;
    width: auto; */
}

.manager .project-progress .progress-box .req-state .project-user-info {
    white-space:nowrap;
    margin: 0;
    min-width: 90px;
}

/* 검수포함 */
.manager .project-progress .progress-box .req-state .fs-icon-clipboard {
    color: var(--color-secondary-p1);
}
.manager .project-progress .progress-box .req-state .fs-icon-clipboard.none {
    color: var(--default-text-color-hint);
}
.manager .project-progress .progress-box .req-state .prj-qc-yn {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-secondary-p1);
    margin: 0 0 0 10px;
    white-space: nowrap;
}

.manager .project-progress .progress-box .req-state .prj-qc-yn.none {
    color: var(--default-text-color-hint);
}





/* .manager .project-progress .progress-box .req-state p:first-child {
    color: #04812E;
} */

/* .manager .project-progress .progress-box .req-state p:nth-child(2) {
    margin-right: 0;
} */

.manager .project-progress .progress-box .req-state .fa-clipboard-check {
    /* margin-right: 5px; */
}

.manager .project-progress .progress-box .video {
    /* margin-top: 0 auto; */
    /* margin-left: auto; */
    align-self: flex-start;
    background-color: #FF6262;
    align-items: center;
    /* align-items: flex-start; */
    margin-left: 0;
}

.manager .project-progress .progress-box .view-detail {
    display: flex;
    background-color: #67A4FF;
    padding: 10px 14px;
    margin-left: 0;
    transition: background-color 0.3s ease-out;
}

.manager .project-progress .progress-box .view-detail:hover {
    background-color: var(--color-primary);
}

.manager .user-logo {
    padding: 5px;
    border: 1px solid #EEEEEE;
    border-radius: 100px;
    margin-right: 20px;
}

.manager .user-logo-img {
    /* margin-right: 20px; */
    border: 2px solid #EEE;
    border-radius: 50px;
    margin-right: 20px;
}

.manager .manager-main .progress-box.req .user-logo {
    margin-right: 5px;
}

.manager .manager-main .progress-box.req .user-logo-img {
    margin-right: 5px;
}

.manager .progress-box.req p:nth-child(n+2) {
    margin-right: auto; 
    /* font-family: 'Inter'; */
    font-size: 14px;
    font-weight: 400;
    /* line-height: 17px; */
    /* letter-spacing: 0em; */
}


.manager .progress-box.req div:nth-child(n+2) {
    /* margin-right: auto;  */
    /* font-family: 'Inter'; */
    font-size: 14px;
    font-weight: 400;
    /* line-height: 17px; */
    /* letter-spacing: 0em; */
}

.manager .progress-box.req p:nth-child(n+2) .prj-stts-cd {
    margin-left: 10px;
    padding: 4px 20px;
}

.manager .progress-box.req p:nth-child(n+2) .prj-stts-cd:hover {
    color: var(--color-primary);
    background-color: white;
}

.manager .project-progress .progress-box.apply p:nth-child(2) {
    margin-bottom: 8px;
}

.manager .project-progress .progress-box .apply-info {
    margin-right: auto;
}

.manager .project-progress .progress-box .apply-info p:nth-child(n+2) {
    margin-bottom: 0;
    margin-left: 10px;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    /* line-height: 20.27px; */
    /* text-align: left; */

}

.manager .project-progress .progress-box .apply-info p:last-child {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-primary);
    /* line-height: 23.17px; */
}

.manager .project-progress .progress-box .apply-btn {
    background-color: #FFFFFF;
    color: var(--color-primary);
}

/* .manager .project-progress .progress-box .progress-box-sub {
    justify-content: space-between;
} */

/* 오른쪽 - 사용자*/
.manager .manager-sub .hr-solid {
    border: 1px solid #D0D0D0;
    width: 100%;
    margin-top: 10px;
}

.manager .manager-sub .project-status-box .hr-solid {
    margin: 10px 0;
}

.manager .manager-sub {
    /* display: flex; */
    /* flex-direction: column; */
    min-width: 325px;
    /* height: 400px; */
    height: 320px;
    /* min-height: 400px; */
    background-color: #FFF;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px 3px #DADCE0;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    /* justify-content: space-around; */
    /* position: relative; */
}

.manager .manager-sub .user-info {
    /* 이미지 크게 */
    position: relative;
    justify-content: flex-start;
    width: 100%;
    /* height: 100%; */
    flex: none;
    background-color: #FFFFFF;
    padding: 0;
}

.manager .user-info .user-info-box {
    /* margin-left: 5px; */
}


.manager .user-info .user-logo {
    /* 사용자 로고 (이미지)일때 */
    /* width: 55px;
    height: 55px; */

    /* 폰트어썸 사람 icon (fa-user-large) */
    font-size: 40px;
    padding: 15px;
    
    /* padding: 5px; */
}

/* 파트너 */
.manager .manager-sub .user-info .login-user-ty-state {
    position: absolute;
    top: 0;
    right: 0;
}

/* 클라이언트 */
.manager .manager-sub .user-info.client {
    position: absolute;
    top: 0;
    right: 0;
}

.manager .manager-sub .project-status-box {
    margin: 0 10px;
    width: 100%;
}

.manager .manager-sub .project-status-box .project-status {
    /* 이미지 크게 */
    position: relative;
    justify-content: space-between; 
    padding: 10px 5px;
    /* margin: 2px; */
    /* margin-bottom: 10px; */
    /* margin: 10px; */
}

.manager .manager-sub.dropia .project-status-box .project-status:hover {
    background-color: transparent;
}

.manager .manager-sub .project-status-box .project-status:last-child {
    margin: 0px;
}

.manager .manager-sub .project-status-box .project-status p:first-child {
    font-size: 16px;
    font-weight: 400;
    /* line-height: 23px; */
    /* letter-spacing: 0em; */
}

.manager .manager-sub .dropia-btn-box {
    width: 100%;
}

.manager .manager-sub .dropia-btn-box .dropia-btn {
    width: 100%;
    padding: 16px 14px;
    transition: background-color 0.3s ease-out;
}

/* .manager .manager-sub .mydropia-btn:nth-child(2) {
    margin-top: 10px;
} */

.manager .manager-sub .dropia-btn-box .dropia-btn:last-child {
    margin-top: 10px;
}


/* .manager .manager-sub .mydropia-btn.partner {
    color: var(--color-secondary-1);
    border: 1px solid var(--color-secondary-1);
}

.manager .manager-sub .mydropia-btn.partner:hover {
    background-color: var(--color-gray-2);
} */

/* .user-logo-img {
    width: 100px;
    height: 100px;
}

.user-logo-img.small {
    width: 20px;
    height: 20px;
} */

/* 데이터 없는 경우, 문구 표출 */
.manager .manager-main .result-info {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
}

/* Pagination */
/* .ui.pagination.menu {
    margin: 0;
    display: inline-flex;
    vertical-align: middle;
} */

.ui.pagination.pagination.menu {
    border: none;
    box-shadow: none;
}

.ui.pagination.menu .item {
    padding: 5px 10px !important;
}

/* Profile.css */
.manager .manager-main .main-title-box {
    justify-content: flex-start;
    align-items: center;
}

.manager .manager-sub .project-status-box .project-status:hover {
    background-color: var(--color-tertiary-2);
    cursor: pointer;
}

.manager .manager-sub .project-status-box .project-status.active {
    background-color: var(--color-tertiary-2);
}

/* 
manager > manager

*/


/* 작업중 */
/* 매니저 태그 */
.manager .manager-tag {
    background-color: var(--color-tertiary);
}

.manager .manager-tag:hover {
    background-color: var(--color-tertiary);
}

/* .manager .manager-main .main-title-container .main-title-box .manager-tag {
    background-color: var(--color-tertiary);
} */

/* Dropdown */
.ui.inline.dropdown>.text {
    font-weight: 400 !important;
    margin-right: 5px;
}

/* 필터링 */
.manager .filtering-option {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px #dadce0;
    /* margin: 75px 0 0; */
    margin: 10px;
    /* width: 100%; */
}

.manager .filtering-option .filtering-header {
    align-items: center;
    background-color: var(--color-tertiary);
    border-radius: 5px 5px 0 0;
    display: flex;
    height: 40px;
    padding: 10px 20px;
    width: 100%;

    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

.manager .filtering-option .filtering-main {
    padding: 10px 20px;
    
}

.manager .filtering-option .filtering-main p {
    margin: 10px 0;
}

.manager .filtering-option .filtering-main .filtering-type {
    margin-top: 10px;
}

.manager .filtering-option .filtering-main .filtering-type label {
    /* flex-basis: auto; */
    /* flex-basis: 70px; */
    font-size: 16px;
    font-weight: 500;
    color: var(--default-text-color);
    /* margin-left: 10px; */
}

.manager .filtering-option .filtering-main .filtering-type .checkbox {
   margin-right: 10px;
}

.manager .filtering-option .filtering-main .filtering-type .field  {
    /* margin: 10px 0; */
    margin-bottom: 20px;
}


.manager .filtering-option .part-divider {
    border: 1px solid rgb(238, 238, 238);
    margin: 20px 0;
}

/* 금액 */
.manager .filtering-option .cost.lower {
    /* margin: 5px 10px 5px 0; */
}

.manager .filtering-option .cost.upper {
    /* margin: 5px 10px; */
}

.manager .filtering-option .cost {
    border: 1px solid var(--default-text-color-hint);
    border-radius: 5px;
    height: 30px;
    outline: none;
    padding: 10px;
    text-align: center;
    width: 80px;
}

.manager .filtering-option .textB {
    font-weight: 500;
    margin: 0 10px ;
}

/* .manager .filtering-option .filtering-main .filtering-type {
    padding: 10px 20px;
} */

/* 지역 */
.manager .filtering-option .sido-group {
    display: grid;
    width: 325px;
    height: 225px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.manager .filtering-option .filtering-factor {
    /* margin: 10px 0; */
}

.manager .filtering-option .filtering-factor label {
    flex-basis: auto;
}

.manager .filtering-option .filtering-factor .checkbox {
    /* margin: 0 10px 0 0; */
    width: 15px;
}