.product-container {
  font-family: 'Noto Sans KR' !important;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.map-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    transition: width 0.3s ease-out;
}
  
.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mapcontrol-container {
  position: fixed;
  bottom: 10%;
  left: 0%;
  width: 30px;
  margin: 10px;
}

.mapcontrol-container .map-select {
  background: #FFF;
  font-size: 12px;
  border-radius: 4px;
}

.mapcontrol-container .btn {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100%;
  height: 30px;
  /* border-radius: none; */
  border: 0.5px solid #CCC;
  cursor: pointer;
}

.mapcontrol-container .btn.active {
  color: #FFF;
  background-color: var(--color-primary);
}

.mapcontrol-container .btn:hover {
  color: #FFF;
  background-color: var(--color-primary-p1);
}

.mapcontrol-container .aerial-image.btn {
  border-radius: 4px 4px 0 0;
}

.mapcontrol-container .normal-image.btn {
  border-radius: 0 0 4px 4px;
}

.sidebar-container {
  position: absolute;
  top: 50px;
  right: 0;
  width: 300px;
  height: calc(100% - 50px);
  transition: right 0.3s ease-out;
}

.sidebar-container.hidden {
  right: -300px;
}

.sidebar-container .product-management {
  width: 100%;
  height: 300px;
  padding: 20px;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 3px 3px 3px 3px #dadce0;
  margin: 0 0 5px 0;
}

.sidebar-container .product-management .header-text {
  padding: 0 0 10px 0;
  border-bottom: 1px solid var(--default-text-color-hint);
}

.sidebar-container .product-management .product-summary-box {
  width: 100%;
  height: 80px;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
}

.sidebar-container .product-management .product-summary-box.primary {
  background-color: var(--color-primary-3);
}

.sidebar-container .product-management .product-summary-box.secondary {
  background-color: #D0FFE3;
}

.sidebar-container .product-management .product-summary-box .sub-text {
  font-weight: 700;
  font-size: 14px;
  margin: 0 0 5px 0;
}

.sidebar-container .product-management .product-summary-box .group {
  margin: 5px 0;
}

.sidebar-container .product-management .product-summary-box .group .content-text-typeA {
  width: 70px;
}

.sidebar-container .product-management .product-summary-box .group .content-text-typeB {
  margin: 0 5px;
}

.sidebar-container .product-management .product-reg-button {
  width: 140px;
  height: 35px;
  background-color: var(--color-secondary);
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
  margin: 20px 0 0 120px;
}

.sidebar-container .product-management .product-reg-button:hover {
  background-color: var(--color-secondary-p2);
}

.sidebar-container .product-list {
  width: 100%;
  height: calc(100% - 305px);
  padding: 20px;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 3px 3px 3px 3px #dadce0;
}

.sidebar-container .product-list .content-text-typeC.input {
  margin: 0 5px;
}

.sidebar-container .product-list .search {
  margin: 20px 0;
}


.sidebar-container .product-list .search .ui.selection.dropdown {
  border: 1px solid var(--default-text-color);
  justify-content: start;
  padding: 0 0 0 10px;
  max-width: 80px;
  height: 30px;
}

.sidebar-container .product-list .search .search-keyword {
  border-radius: 5px;
  margin: 0 0 0 5px;
  border: 1px solid var(--default-text-color);
  outline: none;
  width: 100%;
  height: 30px;
  padding: 5px;
}

.sidebar-container .product-list .product-item {
  border-top: 1px solid var(--default-text-color-hint);
  padding: 10px;
}

.sidebar-container .product-list .product-item .product-item-mark {
  color: #FFF;
  border-radius: 5px;
  width: 70px;
  height: 25px;
  margin: 0 20px 0 0;
  font-weight: 500;
  font-size: 14px;
}

.sidebar-container .product-list .product-item .product-item-mark.green {
  background-color: var(--color-green);;
}

.sidebar-container .product-list .product-item .product-item-mark.blue {
  background-color: var(--color-primary);
}

.sidebar-container .product-list .product-item .product-item-name {
  cursor: pointer;
  width: 150px;
  font-weight: 700;
  font-size: 14px;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-container .product-list .product-item .product-item-name:hover {
  text-decoration: underline;
}

.sidebar-container .sidebar-hide-button {
  background-image: url('../../../public/icons/Arrow_Left.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 60px;
  background-color: #FFF;
  border : 1px solid #CCC;
  border-width: 1px 0 1px 1px;
  position: absolute;
  border-radius: 10px 0 0 10px;
  bottom: 40%;
  right: 100%;
  cursor:pointer;
  transition: right 0.3s ease-out;
}

.substract-container {
  width: 240px;
  height: 320px;
  background-color: #FFF;
  position: absolute;
  top: 50px;
  right: 300px;
  margin: 20px;
  border-radius: 5px;  
  display: none;
  /* display: inline; */
  padding: 20px;
}

.substract-container.active {
  display: inline;
}

.substract-container .product-item-name {
  font-weight: 700;
  color: var(--color-primary);
  margin: 0 0 15px 0;
}

.substract-container .product-item-address {
  font-size: 14px;
  margin: 0 0 15px 0;
}

.substract-container .product-item-detail {
  background-color: var(--color-secondary);
  color: #FFF;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 15px 0 0 0;
}

.substract-container .product-item-detail:hover {
  background-color: var(--color-secondary-p2);
}

.sidebar-container .pagination-container {
  /* padding: 10px 0; */
  margin-top: 25px;
  width: 260px;
  position: absolute;
  bottom: 50px;
}

.search-icon {
  background-image: url('../../../public/icons/search_Icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: var(--color-primary);
  border-radius: 5px;
  position: absolute;
  right: 5%;
}

.bottom-message {
  font-weight: 700;
  width: 240px;
  height: 70px;
  border-radius: 5px;
  margin: 20px;
  background-color: #FFF;
  position: absolute;
  bottom: 0px;
  right: 300px;
  color: var(--default-text-color);
  line-height: 20px;
}