/* .dropia-reg .dropia-reg-main .info {
    width: 100%;
    height: 100%;
} */

.dropia-reg .dropia-reg-main .main-title-container {
    justify-content: space-between
}

.dropia-reg .dropia-reg-main .main-title-box {
    justify-content: flex-start;
    align-items: center;
}

.dropia-reg .dropia-reg-main .main-title-box .tag-flex {
    /* height: 24px;
    margin: 0 4px; */
}

/* 기본정보 - 사용자 관리 */
.dropia-reg .dropia-reg-main .user-mgt-box {
    width: 100%;
    justify-content: space-between;
    /* justify-content: flex-start; */
    /* align-items: f; */
}

.dropia-reg .dropia-reg-main .user-mgt-box .user-mgt {
    width: 100%;
    max-width: 490px;
    /* max-width: 360px; */
    margin-right: 50px;
}

.dropia-reg .dropia-reg-main .act-info {
    min-height: 30px;
    margin-bottom: 15px; 
}

.dropia-reg .dropia-reg-main .act-info:last-child {
    margin-bottom: 0; 
}

.dropia-reg label {
    font-size: 14px;
    font-weight: 400;

    display: flex;
    justify-content: flex-start;;
    align-items: center;
    flex-basis: 123px;
    flex-shrink: 0;
    margin-left: 10px;
    margin-right: 15px;

    white-space:nowrap;
    /* flex-grow: 1; */
}

/* 필수항목 */
.dropia-reg label .required {
    margin-left: 2px;
    color: red;
}

.dropia-reg .dropia-reg-bottom {
    position: relative;
}

.dropia-reg .required-info {
    position: absolute;
    bottom: 0;
    right: 0;
    color: red;
    font-size: 14px;
    font-weight: 400;
}

.dropia-reg .mod-user-info {
    /* background-color: red; */
    /* height: 100%; */

    justify-content: flex-end;
    /* margin-right: 10px; */
    
    /* box-sizing: border-box; */

    /* align-items: center; */
    /* width: 100%;
    justify-content: flex-start;
    align-items: center; */
    align-items: flex-end;
}

.dropia-reg .mod-user-info .pw-change {
    margin-right: 10px;
    color: var(--color-secondary-1);
    border: 1px solid var(--color-secondary-1);
}

.dropia-reg .mod-user-info .pw-change:hover {
    color: #FFFFFF;
    background-color: var(--color-secondary-1);
}
    
.dropia-reg .mod-user-info .user-logo {
    /* 사용자 로고 (이미지)일때 */
    /* width: 100px; */
    /* height: 100px; */
    /* 125 */

    /* 폰트어썸 사람 icon (fa-user-large)  */
    font-size: 80px;
    padding: 25px;
    margin-right: 0;
    margin-bottom: 10px;
    /* margin-right: 30px; */
    /* margin-right: 20px; */
}

.dropia-reg .mod-user-info .user-logo-img {
    /* 사용자 로고 (이미지)일때 */
    /* width: 100px; */
    /* height: 100px; */
    margin-right: 0;
    margin-bottom: 10px;
    /* 125 */

    /* 폰트어썸 사람 icon (fa-user-large)  */
    /* font-size: 80px;
    padding: 25px;
    margin-right: 0;
    margin-bottom: 10px; */
    /* margin-right: 30px; */
    /* margin-right: 20px; */
}

.dropia-reg .act-btn {
    min-width: 77px;
    
    height: 30px;
    padding: 8px 25px;
}

.dropia-reg .dropia-reg-main .user-mgt-box .profile .act-btn + .act-btn {
    margin-top: 10px;
}

.dropia-reg .dropia-reg-main .user-mgt-box .profile .act-btn.del {
    /* color: var(--color-secondary-1);
    border: 1px solid var(--color-secondary-1); */
}

.dropia-reg .dropia-reg-main .user-mgt-box .profile .act-btn.del:hover {
    /* color: #FFFFFF;
    background-color: var(--color-secondary-1-hover); */
}

/* 서명관리 (업로드 버튼) */
.dropia-reg .dropia-reg-main .act-btn.upl-btn {
    /* margin-left: 10px; */
}

/* 기본정보 - 사용자 관리 - 계정유형 */
.dropia-reg .dropia-reg-main .account-type {
    justify-content: space-between;
}

.dropia-reg .dropia-reg-main .account-type label:last-child {
    margin-right: 0px;
}

/* 기본정보 - 활동정보 */
.dropia-reg .dropia-reg-main .act-info .profile-select.select-act-info {
    min-width: 153.79px;
}
.dropia-reg .dropia-reg-main .act-info .profile-select {
    font-size: 14px;
    /* min-width: 116px; */
    min-height: 30px !important;
    background-color: transparent;
    border: 1px solid var(--color-gray-1) !important;
   
    /* border-width: 2px;
    border-style: inset;
    border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) !important ;
    border-image: initial; */
   
    /* border: 1px solid transparent !important; */
    /* flex-grow: 1; */
    /* margin-right: 10px; */

    /* font-size: 14px; */
    /* font-weight: 400; */

    /* font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px; */
}

/* 형태 / 기간 */
.dropia-reg .dropia-reg-main .act-info .form-period {
    justify-content: flex-start;
    width: 315px;
}

/* selection dropdown */
.dropia-reg .dropia-reg-main .act-info .profile-select.border {
    padding: 0em 2.1em 0em 1em;
}

/* 형태 / 기간 - 기간 */
.dropia-reg .dropia-reg-main .act-info .form-period .profile-year-box {
    /* max-width: 153.79px; */
}
.dropia-reg .dropia-reg-main .act-info .form-period .profile-year {
    /* width: auto; */
    /* max-width: 110px; */
    /* width: 153.79px; */
    height: 30px;
    margin: 0 8px;
    text-align: center;
    overflow: visible;
    margin-left: 10px;
}

.dropia-reg .dropia-reg-main .act-info .form-period span {
    font-size: 14px;
    font-weight: 400;
    /* line-height: 20.27px; */
    /* text-align: left; */
}

.dropia-reg .dropia-reg-main .act-info .profile-select + .profile-select {
    margin-left: 10px;
}

.dropia-reg .dropia-reg-main .main-title-box .field {
    margin-left: 15px;
    /* flex-grow: 1; */
}

.dropia-reg .dropia-reg-main .field label {
    flex-basis: auto;
    margin-left: 5px;
    /* flex-grow: 1; */
}

.dropia-reg .dropia-reg-main .act-info.intro {
    align-items: flex-start;
}

.dropia-reg .dropia-reg-main .act-info.intro label {
    margin-top: 5px;
}

.dropia-reg .dropia-reg-main .act-info.intro textarea {
    width: 100%;
    min-height: 150px;
    outline: none;
    padding: 4px 8px;

    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    text-align: left;

    position: relative;
}

.dropia-reg .dropia-reg-main .info-btn {
    display: flex;
    background-color: var(--color-gray-1);
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    transition: background-color 0.3s ease-out;
    cursor: default;
}

.dropia-reg .dropia-reg-main .info-btn:hover {
    background-color: var(--color-gray-p1);
    /* background-color: var(--default-text-color); */
    /* margin-left: 10px; */
}

/* 협력 업체 / 기관 */
.dropia-reg .dropia-reg-main .act-info .info-input.company-nm {
    background-color: var(--color-gray-3);
    margin-right: 10px;
}

.dropia-reg .dropia-reg-main .act-info .info-input.company-nm span {
    padding: 0;
}

/* .dropia-reg .dropia-reg-main .info-input {
    margin-right: 10px;
} */


.dropia-reg .dropia-reg-main .act-info .info-input.company-nm .contractors {
    border: none;
    outline: none;
    background-color: var(--color-gray-3);
    max-width: 100px;
}

.dropia-reg .dropia-reg-main .act-info .info-input.company-nm .fa-xmark {
    margin-left: 8px;
}
.dropia-reg .dropia-reg-main .info-btn.plus {
    margin-left: 10px;
}
.dropia-reg .dropia-reg-main .info-btn .fa-plus,
.dropia-reg .dropia-reg-main .info-btn .fa-arrows-rotate {
    margin-right: 5px;
}

.dropia-reg .dropia-reg-main .act-info .info-input .fa-xmark {
    /* margin-right: 10px; */
}

.dropia-reg .dropia-reg-main .act-info .info-input span {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    padding: 0 14px 0 6px;
}

/* 분야 & 회사 연혁 - 여러줄 */
.dropia-reg .dropia-reg-main .act-info.company-history label {
    align-items: flex-start;
    padding: 10px 0;
}
/* 분야 */
/* .dropia-reg .dropia-reg-main .act-info.company-history .info-field {
    margin-bottom: 10px;
} */
/* 회사 연혁 */
.dropia-reg .dropia-reg-main .act-info.company-history .history-box {
    margin-bottom: 15px;
    /* height: 30px; */
}

.dropia-reg .dropia-reg-main .act-info .history {
    /* width: 100%; */
    flex: 1;
}

.dropia-reg .dropia-reg-main .act-info .history:nth-child(2) {
    /* width: 100%; */
    flex: 2;
    /* margin-left: 10px; */
}
.dropia-reg .dropia-reg-main .act-info.company-history .history-box .info-input.date {
    margin-right: 10px;
}
.dropia-reg .dropia-reg-main .border {
    /* width: 100; */
    border-radius: 5px;

    /* selection dropdown */
    border: 1px solid var(--color-gray-1) !important;
}

/* selection dropdown */
/* .ui.selection.dropdown>.dropdown.icon {
    position: absolute;
    top: .78571429em;
    right: 1em !important;
} */

.ui.selection.dropdown>.dropdown.icon {
    right: 5px !important;
}

.dropia-reg .dropia-reg-main .info-input {
    /* overflow: hidden; */
    white-space: nowrap;
    text-align: left;
    outline: none;
    padding: 4px 8px;
    max-height: 30px;

    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    /* line-height: 20.27px; */

}

.dropia-reg .dropia-reg-main .info-input {
    /* margin-right: 10px; */
}

.dropia-reg .dropia-reg-main .info-input:nth-child(2) {
    margin-right: 0px;
}

.dropia-reg .dropia-reg-main .info-input + .info-input {
    /* margin-left: 10px; */
    /* margin-left: 10px; */
}

.dropia-reg .dropia-reg-main .info-input + .info-input:nth-child(n+2) {
    /* margin-left: 10px; */
}

.dropia-reg .dropia-reg-main .info-input:first-child {
    margin-left: 0;
    /* margin-right: 10px; */
}

.dropia-reg .dropia-reg-main .act-info .move {
    /* background-color: var(--color-gray-1); */
    padding: 0 8px;
    /* margin-left: 10px; */
    margin: 0 10px;
    /* padding: 0; */
    /* margin: 0; */
}

.dropia-reg .dropia-reg-main .act-info .info-btn .fa-plus.history {
   margin-right: 0;
}

/* 주민등록번호 */
/* .dropia-reg .dropia-reg-main .act-info.reg-type {
    margin-left: 10px;
} */

.dropia-reg .dropia-reg-main .act-info .rrn-info {
    justify-content: flex-start ;
    /* width: 220px; */
}

.dropia-reg .dropia-reg-main .act-info .rrn-info .info-input {
    margin-right: 0px;
}

.dropia-reg .dropia-reg-main .act-info .rrn-info .info-input.num {
    /* width: 72px; */
    width: 160px;
}

.dropia-reg .dropia-reg-main .act-info .rrn-info .fa-minus {
    margin: 0 5px;
}

/* 신분증 img */
.dropia-reg .dropia-reg-main .act-info .reg-img-add {
    justify-content: flex-start;
}


/* 신분증 변경버튼 */
.dropia-reg .dropia-reg-main .info-btn.change {
    margin-left: 20px;
}

/* 사업장 주소 */
.dropia-reg .dropia-reg-main .act-info .signatory label {
    flex-basis: auto;
}

.dropia-reg .dropia-reg-main .act-info .signatory label:first-child {
    margin-left: 0;
}

.dropia-reg .dropia-reg-main .act-info .signatory .info-input {
    width: 85px;
}

.dropia-reg .dropia-reg-main .act-info .adress {
    max-width: 262px;
}

/* 자격증관리 */
.dropia-reg .dropia-reg-main .auth-mgt-box {
   width: 100%;
   justify-content: flex-start;
   align-items: flex-end;

   /* padding: 0 10px; */
}

.dropia-reg .dropia-reg-main .info-btn.cert {
    /* background-color: var(--color-gray-1); */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    /* margin-bottom: 10px; */
}

.dropia-reg .dropia-reg-main .auth-mgt-box .info-input {
    margin-right: 0;
 }

.dropia-reg .dropia-reg-main .auth-mgt {
    /* display: flex;
    flex-grow: 1; */
    /* max-width: 500px; */
    /* margin-right: 20px; */
}

.dropia-reg .dropia-reg-main .auth-mgt:last-child {
    /* display: flex;
    flex-grow: 1;
    width: 100%; */
    /* margin-left: 20px; */
}

.dropia-reg .rrn-img-box {
    /* width: 170px;
    height: 110px;
    margin-right: 10px; */
}

.dropia-reg .rrn-img-box .rrn-img {
    /* width: 100%; */
    /* height: 100%; */
    /* min-height: 100px; */
    /* height: 100%; */
    /* width: 100%;
    height: 100%; */
    /* background-image: url('../../../public/images/ex-registration-card.svg'); */
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    /* margin-left: 20px; */
}

.dropia-reg .rrn-img-box .rrn-img-img {
    /* width: 100%; */
    /* height: 100%; */
}

/* 자격증 관리 */
.dropia-reg .dropia-reg-main .auth-mgt-box.cert  {
    /* width: 100%;
    justify-content: flex-start; */
    align-items: flex-start;
    justify-content: center;
}
.dropia-reg .dropia-reg-main .auth-mgt-box.cert .rrn-img-box {
    margin-right: 0px;
}
.dropia-reg .dropia-reg-main .auth-mgt-box.cert .cert-img .rrn-img-box.signature {
    margin-right: 10px;
}
.dropia-reg .dropia-reg-main .act-info.cert-img-box {
    flex-wrap: wrap;
}
.dropia-reg .dropia-reg-main .act-info .info-input.cert {
    max-width: 170px;
    margin-top: 5px;
    margin-left: 0;
}
.dropia-reg .dropia-reg-main .act-info .cert-img {
    margin: 0 20px 30px 0;
    /* 이미지 크게 */
    position: relative;
}
.dropia-reg .dropia-reg-main .auth-mgt-box.cert .info-btn.cert {
    /* margin-left: 0; */
    margin-bottom: 30px;
}
.dropia-reg .dropia-reg-main .act-info .cert-img .rrn-img.cert {
    margin-bottom: 10px;
    /* position: relative; */
    /* background-image: url('../../../public/images/ex-certificate-image.svg'); */
}
.dropia-reg .dropia-reg-main .act-info .cert-img .fa-trash-can {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px;

    /* gap: 0px; */
    background-color: #FFFFFF;
    /* background-color: #FFFFFFB2; */
    /* opacity: 0px; */

    border-radius: 4px;
    border: 1px solid var(--gray-1, #808080);
    /* font-size: 24px; */
    /* background-image: url('../../../public/images/ex-certificate-image.svg'); */
    cursor: pointer;
    transition: background-color 0.3s ease-out;
}

.dropia-reg .dropia-reg-main .act-info .cert-img .fa-trash-can:hover {
    background-color: #D8D8D8;
}

/* 계좌관리 */
.dropia-reg .dropia-reg-main .act-info .doc-info {
    /* max-width: 350px; */
    max-width: 168px;
    /* max-width: 262px; */
}

/*  */
/* 포트폴리오 - 자격증관리 */
.dropia-reg .portfolio-box-list {
    /* width: 100%;
    height: 100%;
    padding: 0 20px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* margin-right: auto; */
}

.dropia-reg .portfolio-box {
    /* width: 230px;
    height: 100%;
    margin: 0 20px 30px 0; */
    position: relative;
    /* width: 200px; */
    /* width: 100%; */
    height: 300px;
    margin: 20px;
}

.dropia-reg .portfolio-box:hover {
    /* border: 3px solid var(--color-primary); */
    /* box-shadow: 0 0 0 1px #333 inset; */
    outline: 3px solid var(--color-primary);
    outline-offset: +3px;
}

.dropia-reg .rrn-img.portfolio-img {
    background-image: url('../../../public/images/ex-portfolio-img\(1\).jpg');
    height: 180px;
    margin-bottom: 5px;
}

.dropia-reg .rrn-img.portfolio-img-img {
    /* margin-bottom: 5px; */

    width: 200px !important;
    height: 200px !important;
    background-size: 100%;
    border: 1px solid #EEE;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.dropia-reg .rrn-img-box.portfolio-box p {
    font-family: 'Inter';
    font-size: 16px;
    /* font-weight: 700;
    line-height: 19.36px; */
    margin-right: auto;
    margin-bottom: 5px;
}

.dropia-reg .rrn-img-box.portfolio-box span {
    margin-left: auto;
    font-weight: 400;
    font-size: 12px;
}

.dropia-reg .rrn-img-box.portfolio-box span:last-child {
    margin-top: 5px;
    font-size: 14px;
}

.dropia-reg .portfolio-menubar {
    justify-content: space-between;
    margin-bottom: 20px;
}

.dropia-reg .portfolio-menubar .main-text {
    margin-bottom: 0px;
}

/* .dropia-reg .portfolio-menubar .setting {
    color: var(--color-secondary-1);
    border: 1px solid var(--color-secondary-1);
} */
.dropia-reg .green-tag {
    color: var(--color-secondary-1);
    border: 1px solid var(--color-secondary-1);
}

.dropia-reg .green-tag:hover {
    color: var(--color-secondary-1);
    background-color: #FFFFFF;
}

.dropia-reg .portfolio-menubar .fa-plus {
    margin-right: 5px;
}

.dropia-reg .portfolio-menubar .fa-plus,
.dropia-reg .portfolio-menubar .fa-gear {
    margin-right: 5px;
}

.dropia-reg .portfolio-list {
    /* justify-content: flex-start;
    flex-wrap: wrap; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding : 0 10px;
    /* overflow-y: auto; */
}

.dropia-reg .dropia-reg-sub .project-status-box .project-status:hover {
    background-color: var(--color-secondary-2);
    cursor: pointer;
}

.dropia-reg .dropia-reg-sub .project-status-box .project-status.active {
    background-color: var(--color-secondary-2);
}

.dropia-reg .dropia-reg-sub.profile {
    /* height: 540px; */
}

.ui.pagination.menu {
    width: 100%;
}

/* 저장하기 버튼 */
.dropia-reg .profile-btn {
    margin-top: 20px;
    padding: 12px 30px;
    /* max-width: 120px; */
    /* flex-basis: 50px; */
    transition: background-color 0.3s ease-out;
}
.dropia-reg .profile-btn.disabled {
    /* background-color: #e6e6e6; */
    background-color: var(--color-secondary-2);
}
.dropia-reg .profile-btn.none {
    display: none;
}
.dropia-reg .profile-btn.del {
    color: var(--color-red);
    border: 1px solid var(--color-red);
    margin-right: 10px;
}
.dropia-reg .profile-btn.del:hover {
    background-color: var(--color-red);
    color: #FFFFFF;
}
.dropia-reg .profile-btn.info-btn.close {
    margin-left: 10px;
}


/* 추가 */
.progress-box .prj-result p:first-child {
   margin-bottom: 0;
   margin-right: 10px;
}

/* 이미지 크게 */
.dropia-reg .dropia-reg-main .act-info .image-container {
    position: relative;
}
  
.dropia-reg .dropia-reg-main .act-info .image-container .zoom-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.dropia-reg .dropia-reg-main .act-info .image-container .zoom-icon .fa-magnifying-glass {
    /* font-size: 24px; */
    width: 25px;
    height: 25px;
    color: #333;
}
/* .dropia-reg .dropia-reg-main .act-info .image-container .zoom-icon i {
    font-size: 24px;
    color: #333;
} */
  
.dropia-reg .dropia-reg-main .act-info .image-container .zoom-icon:hover {
    opacity: 0.9;
}

.dropia-reg .dropia-reg-main .act-info .modal-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    Z-index: 10;
}
  
.dropia-reg .dropia-reg-main .act-info .modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-select {
    border: none; 
} */
  
.dropia-reg .dropia-reg-main .act-info .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}
  
.dropia-reg .dropia-reg-main .act-info .close:hover {
    color: #999;
}

/* 자격증 이미지 크게보기 */
.cert-img-container {
    position: relative;
}

.magnifying-glass-icon,
.trash-icon {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    /* transition: 'opacity' 0.3s ease; */

    width: 25px;
    height: 25px;
}

.cert-img-container:hover .magnifying-glass-icon,
.cert-img-container:hover .trash-icon {
    /* opacity: 1; */
    opacity: 0.9;
}

.certi-modal {
    /* display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    Z-index: 10;
}

.certi-modal-content {
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; */
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    /* max-width: 90%; */
    /* max-height: 90%; */
    max-width: 60%;
    /* max-height: auto; */
    overflow: auto;

    display: flex;
    justify-content: center;
    align-items: center;
}

.certi-modal-image {
    max-width: 90%;
    /* max-height: auto; */
    /* max-width: 90%; */
    /* max-height: 90%; */
    position: relative;
}

.certi-close {
    /* position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 24px;
    cursor: pointer; */
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

.cert-img-container {
    position: relative;
}

.magnifying-glass-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* .cert-img-container:hover .magnifying-glass-icon {
    opacity: 1;
} */

/* 신분증 사진 */
.reg-img-add .user-logo-img {
    width: 100%;
    height: 100%;
    border-radius: 0;
    /* background-size: contain; */
}

/* 서명 */
.auth-mgt-box.cert .user-logo-img {
    width: 100%;
    height: 100%;
    /* background-size: contain; */
}

/* 매니저 태그 */
.dropia-reg .manager-tag {
    background-color: var(--color-tertiary);
}

.dropia-reg .manager-tag:hover {
    background-color: var(--color-tertiary);
}
