.app-container.footer {
    width: 100%;
}

.footer-container {
  font-family: 'Noto Sans KR';
  background-color: #FFF;
  /* background-color: var(--color-gray-3); */
  height: 200px;  
  z-index: 10;
  /* width: 100%; */
}
  
.footer-container .footer-image {
  background-image: url('../../../../public/images/Geocapture_logo.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 220px;
  height: 50px;
  margin: 20px 0 20px 40px;
}

.footer-container .desk-info {
    margin: 0 0 0 50px;
}

.footer-container .company-info {

}

.footer-container .footer-textA {
  font-weight: 700;
  font-size: 14px;
  margin: 10px 0;
}

.footer-container .footer-textB {
  font-weight: 700;
  font-size: 20px;
  margin: 10px 0;
}

.footer-container .footer-textC {
  font-weight: 500;
  font-size: 14px;
  margin: 10px 0;
}

.footer-container .footer-textD {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: var(--default-text-color);
  margin: 20px 0 20px 40px;
}