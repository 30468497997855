/* .dropia-reg .portfolio-menubar .main-text {
    width: auto;
} */
/* .dropia-reg .dropia-reg-main .info-btn.cert {

} */

.dropia-reg .main-title-box .certification-partner-mark {
    background-image: url("../../../public/images/certification-partner-mark.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 86px;
    height: 22px;
}

.dropia-reg .portfolio-box .portfolio-img {
    margin-bottom: 10px;
    /* background-size: contain; */
}

.dropia-reg .portfolio-box .portfolio-btn-box {
    position: absolute;
    /* top: 10px;
    right: 10px; */
    top: -15px;
    right: 0px;
    font-size: 24px;
}

.dropia-reg .portfolio-box .portfolio-btn-box.none {
   display: none;
}

.dropia-reg .portfolio-box .portfolio-btn-box .portfolio-btn {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: background-color 0.3s ease-out;
}

.dropia-reg .portfolio-box .portfolio-btn-box .portfolio-btn:nth-child(n+2) {
    margin-left: 5px;
}

.dropia-reg .portfolio-box .portfolio-btn-box .portfolio-btn.portfolio-minus {
    background-image: url('../../../public/icons/basic-minus.svg');
}

.dropia-reg .portfolio-box .portfolio-btn-box .portfolio-btn.portfolio-minus:hover {
    background-image: url('../../../public/icons/hover-minus.svg');
}

/* .dropia-reg .portfolio-box .portfolio-btn-box .common-btn.hover-minus {
    background-image: url('../../../public/icons/hover-minus.svg');
} */

.dropia-reg .portfolio-box .portfolio-btn-box .portfolio-btn.portfolio-check {
    background-image: url('../../../public/icons/basic-check.svg');
}

.dropia-reg .portfolio-box .portfolio-btn-box .portfolio-btn.portfolio-check:hover {
    background-image: url('../../../public/icons/red-check.svg');
}

.dropia-reg .portfolio-box .portfolio-btn-box .portfolio-btn.portfolio-check.selected {
    background-image: url('../../../public/icons/red-check.svg');
}

/* .dropia-reg .portfolio-box .portfolio-btn-box .common-btn.red-check {
    background-image: url('../../../public/icons/red-check.svg');
} */

.dropia-reg .portfolio-box.no-outline:hover {
    /* border: 3px solid var(--color-primary); */
    /* box-shadow: 0 0 0 1px #333 inset; */
    outline: none;
}

/* .dropia-reg .dropia-reg-main .info-btn.none{
    display: none;
} */

/* 포트폴리오 달력 */
.dropia-reg .dropia-reg-main .info-input.date {
    min-width: 185px;
    
    /* display: flex; */
    /* justify-content: space-between; */
}

/* input 요소의 날짜 형식과 위치 조정 */
.info-input.date {
    position: relative;
    display: inline-block;
    /* width: 100%; */
}
  
.info-input.date::-webkit-calendar-picker-indicator {
    position: absolute;
    /* 달력 아이콘의 위치를 조정합니다. */
    right: 10px; 
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    /* 아이콘의 투명도를 설정합니다. */
    /* opacity: 0.5;  */
}

.info-input.date::-webkit-datetime-edit {
    /* 기본 텍스트를 투명하게 만듭니다. */
    color: transparent; 
}

.info-input.date::-webkit-datetime-edit-fields-wrapper {
    /* 원하는 색상으로 설정합니다. */
    color: #000; 
}

/* .info-input.date::after {
    content: attr(value);
    position: absolute;
    /* 날짜 텍스트의 위치를 조정합니다. 
    left: 10px; 
    top: 50%;
    transform: translateY(-50%);
    /* 원하는 색상으로 설정합니다. 
    color: #000; 
    pointer-events: none;
} */

/* 참여율 */
.dropia-reg .dropia-reg-main .act-info .profile-select.participation-rate {
    min-width: 185px;
}
.dropia-reg .dropia-reg-main .act-info .profile-select.participation-rate.read-only {
    pointer-events: none;
}


.dropia-reg .dropia-reg-main .act-info .add-file.none {
    display: none;
}
