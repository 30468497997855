/* .manager .manager-main .info {
    width: 100%;
    height: 100%;
} */

.manager .manager-main .main-title-container {
    justify-content: space-between
}

.manager .manager-main .main-title-box {
    justify-content: flex-start;
    align-items: center;
}

/* 기본정보 - 사용자 관리 */
.manager .manager-main .user-mgt-box {
    width: 100%;
    justify-content: flex-start;
    /* align-items: f; */
}

.manager .manager-main .user-mgt-box .user-mgt {
    width: 100%;
    /* max-width: 360px; */
    margin-right: 150px;
}

.manager .manager-main .act-info {
    min-height: 30px;
    margin-bottom: 15px; 
}

.manager .manager-main .act-info:last-child {
    /* margin-bottom: 0;  */
}

/* .manager label {
    font-size: 14px;
    font-weight: 400;

    display: flex;
    justify-content: flex-start;;
    align-items: center;
    flex-basis: 123px;
    flex-shrink: 0;

    margin-left: 10px;
    margin-right: 15px;

    /* flex-grow: 1; 
}  */

/* 필수항목 */
.manager label .required {
    margin-left: 2px;
    color: red;
}

.manager .manager-bottom {
    position: relative;
}

.manager .required-info {
    position: absolute;
    bottom: 0;
    right: 0;
    color: red;
    font-size: 14px;
    font-weight: 400;
}

.manager .mod-user-info {
    /* background-color: red; */
    /* height: 100%; */

    justify-content: flex-end;
    /* margin-right: 10px; */
    
    /* box-sizing: border-box; */

    /* align-items: center; */
    /* width: 100%;
    justify-content: flex-start;
    align-items: center; */
    align-items: flex-end;
}

.manager .mod-user-info .pw-change {
    margin-right: 10px;
    color: var(--color-secondary-1);
    border: 1px solid var(--color-secondary-1);
}

.manager .mod-user-info .pw-change:hover {
    color: #FFFFFF;
    background-color: var(--color-secondary-1);
}
    
.manager .mod-user-info .user-logo {
    /* 사용자 로고 (이미지)일때 */
    /* width: 100px; */
    /* height: 100px; */
    /* 125 */

    /* 폰트어썸 사람 icon (fa-user-large)  */
    font-size: 80px;
    padding: 25px;
    margin-right: 0;
    margin-bottom: 10px;
    /* margin-right: 30px; */
    /* margin-right: 20px; */
}

.manager .mod-user-info .user-logo-img {
    /* 사용자 로고 (이미지)일때 */
    /* width: 100px; */
    /* height: 100px; */
    margin-right: 0;
    margin-bottom: 10px;
    /* 125 */

    /* 폰트어썸 사람 icon (fa-user-large)  */
    /* font-size: 80px;
    padding: 25px;
    margin-right: 0;
    margin-bottom: 10px; */
    /* margin-right: 30px; */
    /* margin-right: 20px; */
}

.manager .act-btn {
    min-width: 77px;
    
    height: 30px;
    padding: 8px 25px;
    transition: background-color 0.3s ease-out;
}

.manager .manager-main .user-mgt-box .profile .act-btn + .act-btn {
    /* margin-top: 10px; */
}

/* 기본정보 - 사용자 관리 - 계정유형 */
.manager .manager-main .account-type {
    justify-content: space-between;
}

.manager .manager-main .account-type label:last-child {
    margin-right: 0px;
}

.manager .manager-main .main-title-box .field {
    /* margin-left: 15px; */
    /* flex-grow: 1; */
}

.manager .manager-main .field label {
    flex-basis: auto;
    margin-left: 5px;
    /* flex-grow: 1; */
}

/* .manager .manager-main .act-info .info-input span {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    padding: 0 14px 0 6px;
} */

/* 분야 & 회사 연혁 - 여러줄 */
/* .manager .manager-main .act-info.company-history label {
    align-items: flex-start;
    padding: 10px 0;
} */

.manager .manager-main .border {
    /* width: 100; */
    border-radius: 5px;

    /* selection dropdown */
    border: 1px solid var(--color-gray-1) !important;
}

.manager .manager-main .info-input {
    /* overflow: hidden; */
    white-space: nowrap;
    text-align: left;
    outline: none;
    padding: 4px 8px;
    max-height: 30px;

    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 400;
    /* line-height: 20.27px; */

}

/* .manager .manager-main .info-input:nth-child(2) {
    margin-right: 0px;
}

.manager .manager-main .info-input:first-child {
    margin-left: 0;
} */

.ui.pagination.menu {
    width: 100%;
}

/* 저장하기 버튼 */
.manager .profile-btn {
    margin-top: 20px;
    padding: 12px 30px;
    /* max-width: 120px; */
    /* flex-basis: 50px; */
    transition: background-color 0.3s ease-out;
}
.manager .profile-btn.disabled {
    /* background-color: #e6e6e6; */
    background-color: var(--color-secondary-2);
}
