.modal {
    z-index: 100;
    position: fixed;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
  }
  
  .modal__header {
    width: 100%;
    height: 40px;
    background: var(--theme-color);
    color: #FFFFFF;
    display: inline-block;
    border-radius: 8px 8px 0 0;
  }
  
  
  .modal__header .modal_header {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    position: relative;
    top: 9px;
    bottom: 6px;
    padding-left: 20px;
  }

  .modal__header .modal_icon{
    float: right;
    position: relative;
    top: 12px;
    right: 10px;
    bottom: 12px;
  }

  .modal_icon .code.x.icon{
    color: var(--default-text-color-hint);
    margin-top: 2px;
    margin-right: 20px;
  }

  .modal_icon .code.x.icon:hover {
      background-color: var(--default-text-color-hint);
      color: #FFF;
  }
  
  .modal__content {
    height: 100%;
  }
  
  .modal__footer {
    padding: 1rem 0.5rem;
  }