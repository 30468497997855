.send-msg-container > * {
    font-family: 'Noto Sans KR' !important;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 17.38px;
    text-align: left; */
}

.send-msg-container {
    font-family: 'Noto Sans KR' !important;
    top: calc(50% - (195px/2));
    left: calc(50% - (800px/2));
    width: 800px;
    height: 195px;
    padding: 20px;
    /* max-height: 80%; */
    /* overflow-y: auto; */
}
    
.send-msg-container form {
    width: 100%;
}

.send-msg-container .send-msg-container-box .send-msg-who {
    margin-bottom: 10px;
    justify-content: flex-start;
}

.send-msg-container .send-msg-container-box .send-msg-who .user-logo{
    font-size: 16px;
    border: 2px solid #EEE;
    border-radius: 50px;
    margin-right: 8px;
}


.send-msg-container .send-msg-container-box .send-msg-who .user-logo-img {
    width: 25px;
    height: 25px;
    border: 2px solid #EEE;
    border-radius: 50px;
    margin-right: 8px;
}


.send-msg-container .send-msg-container-box .send-msg-who p {
    /* font-family: 'Noto Sans KR'; */
    font-size: 14px;
    font-weight: 400;
    /* line-height: 20.27px;
    text-align: left; */
    margin-left: 5px;
}

.send-msg-container .send-msg-container-box .input-msg {
    border-radius: 5px;
    border: 1px solid var(--color-gray-1) !important;
    /* min-height: 80px; */
    padding: 4px 8px;
    outline: none;
}

.send-msg-container .send-msg-container-box .msg-box {
    margin-bottom: 10px;
}

.send-msg-container .send-msg-container-box .msg-box .msg-code {
    justify-content: flex-start;
    margin-left: 15px;
    flex: 1;
}

.send-msg-container .send-msg-container-box .msg-box .msg-code .field:last-child {
    margin-left: 15px;
}

.send-msg-container .send-msg-container-box .msg-box .msg-code .field label {
    margin-left: 5px;
}


.send-msg-container .send-msg-container-box .input-msg.msg-title {
    /* margin-bottom: 10px; */
    flex: 2;
}


.send-msg-container .send-msg-container-box .input-msg.msg-contents {
    min-height: 80px;

    /* font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.38px;
    text-align: left; */

}

/* 버튼 */
.send-msg-container .send-msg-container-box .send-btn-box {
    /* right: 0; */
    justify-content: right;
    margin-top: 10px;
}


.send-msg-container .send-msg-container-box .send-btn-box .send-btn  {
    padding: 4px 18px;
    transition: background-color 0.3s ease-out;
}

.send-msg-container .send-msg-container-box .send-btn-box .send-btn.cancel {
    background-color: var(--color-gray-2);
    color: var(--color-gray-1);
}

.send-msg-container .send-msg-container-box .send-btn-box .send-btn.cancel:hover {
    background-color: var(--color-gray-1);
    color: #FFFFFF;
}

.send-msg-container .send-msg-container-box .send-btn-box .send-btn.send {
    background-color: var(--color-tertiary);
    margin-left: 10px;
}

.send-msg-container .send-msg-container-box .send-btn-box .send-btn.send:hover {
    background-color: var(--color-tertiary-p1);
}

/* .dropia-reg .dropia-reg-main .border {
    border-radius: 5px;
    border: 1px solid var(--color-gray-1) !important;
} */